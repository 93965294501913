import "./base.css"

import CheckboxStyles from "./checkbox.module.css"
import React from "react"
import ReactMarkdown from "react-markdown"
import WrapWithError from "./wrap-with-error"
import classNames from "classnames"
import rehypeRaw from "rehype-raw"
import rehypeSanitize from "rehype-sanitize"

const Checkbox = props => {
    return (
        <div
            className={classNames(CheckboxStyles.block, {
                [CheckboxStyles.error]: props.error?.[props.register.name],
            })}
        >
            <WrapWithError error={props.error?.[props.register.name]} id={props.register.name}>
                <input
                    {...props.register}
                    type="checkbox"
                    id={props.register.name}
                    className="hiddenInput"
                    disabled={props.disabled}
                    required={props.required}
                    aria-invalid={!!props.error?.[props.register.name]}
                    aria-errormessage={"err-" + props.register.name}
                />
                <label htmlFor={props.register?.name}>
                    {props.required ? (
                        <>
                            {props.label}&nbsp;<span className={CheckboxStyles.required}>*</span>
                        </>
                    ) : (
                        <ReactMarkdown rehypePlugins={[rehypeRaw, rehypeSanitize]} linkTarget="_blank">
                            {props.label}
                        </ReactMarkdown>
                    )}
                </label>
            </WrapWithError>
        </div>
    )
}

export default Checkbox
