import {
    Align,
    Color,
    MaxWidth,
    TypographyAlign,
    TypographyColor,
    TypographyMaxWidth,
    alignOptions,
} from "../components/typography"
import { types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../Typography"

import React from "react"

export interface HeadlineProps {
    text: TypographyElement[]
    color?: TypographyColor
    index?: number
    align?: TypographyAlign
}

const Headline: types.Brick<HeadlineProps> = ({ color, index, align }) => {
    return (
        <div key={index}>
            <Color color={color}>
                <Align align={align || TypographyAlign.Center}>
                    <MaxWidth mdMaxWidth={TypographyMaxWidth["70%"]}>
                        <Typography allowList={["h3", "h4", "small", "sup", "link", "bold", "centeredCheckList"]} />
                    </MaxWidth>
                </Align>
            </Color>
        </div>
    )
}

Headline.schema = {
    name: "Headline",
    label: "Headline",
    hideFromAddMenu: true,
    sideEditProps: [
        {
            name: "align",
            label: "Align",
            type: types.SideEditPropType.Select,
            selectOptions: {
                // show center option as first, because it was previously the default value
                options: alignOptions.sort((a, b) => (a.value === TypographyAlign.Center ? -1 : 1)),
                display: types.OptionsDisplay.Select,
            },
        },
    ],
    getDefaultProps: (): HeadlineProps => ({
        align: TypographyAlign.Center,
        text: [
            {
                type: "h3",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet",
                    },
                ],
            },
        ],
    }),
}

export default Headline
