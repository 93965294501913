import { Align, Color, TypographyAlign } from "./../components/typography"
import { Repeater, types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "./../Typography"

import React from "react"

export interface ProductTeaserItemProps {
    headline: TypographyElement[]
    text: TypographyElement[]
    index?: number
}

const ProductTeaserItem: types.Brick<ProductTeaserItemProps> = ({ index }) => {
    return (
        <li key={index} className="max-w-[140px] sm:max-w-40 md:max-w-44 lg:max-w-60">
            <Align align={TypographyAlign.Left} className="mb-3">
                <Typography block="copytext-small" allowList={["bold"]} propName="headline" />
            </Align>
            <Align align={TypographyAlign.Left}>
                <Typography block="copytext-teaser" allowList={["bold"]} propName="text" />
            </Align>
        </li>
    )
}

ProductTeaserItem.schema = {
    name: "ProductTeaserItem",
    label: "Text Item",
    hideFromAddMenu: true,
    getDefaultProps: (): ProductTeaserItemProps => ({
        headline: [
            {
                type: "h3",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consetetur",
                        bold: true,
                    },
                ],
            },
        ],
        text: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
                    },
                ],
            },
        ],
    }),
}

export default ProductTeaserItem
