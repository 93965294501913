import { Repeater, types, usePageValues } from "react-bricks/frontend"
import { Spacer, SpacerVariant } from "../components/layout"
import { asterisk, micronutrients, nutritionalValues, otherSubstances } from "../../shared/config/nutrition"

import React from "react"
import { Table } from "../components/table"
import { usePageContext } from "../../context/page"

interface OtherSubstance {
    id: string
    text: string
    bold?: boolean
}

export interface NutritionTableProps {
    spacerTop: boolean
    spacerBottom: boolean
}

const NutritionTable: types.Brick<NutritionTableProps> = ({ spacerTop, spacerBottom }) => {
    const { page: pageContext, tag } = usePageContext()

    const [pageValues] = usePageValues()

    const page = pageContext || pageValues

    let language = page.language

    if (tag === "CH") {
        language = language + "-CH"
    }

    // @ts-ignore
    const micronutrientsTable = micronutrients[language] || micronutrients[language.split("-")[0]]
    // @ts-ignore
    const nutritionalValuesTable = nutritionalValues[language] || nutritionalValues[language.split("-")[0]]
    // @ts-ignore
    const asteriskTable = asterisk[language] || asterisk[language.split("-")[0]]
    // @ts-ignore
    const otherSubstancesAdd = otherSubstances[language] || otherSubstances[language.split("-")[0]]

    return (
        <>
            <Spacer variant={!spacerTop ? SpacerVariant.None : undefined} />
            <Repeater propName="centerText" />
            <div className="mx-auto box-border w-full px-0 sm:px-6.5 md:px-10 lg:max-w-[1560px] lg:px-15">
                {micronutrientsTable && <Table head={micronutrientsTable.head} body={micronutrientsTable.body} />}
                <Spacer variant={SpacerVariant.Small} />
                <div className="sm:flex">
                    {nutritionalValuesTable && (
                        <div>
                            <Table head={nutritionalValuesTable.head} body={nutritionalValuesTable.body} />
                        </div>
                    )}
                    <div className="mt-7 box-border w-full flex-shrink-0 px-6.5 sm:mt-0 sm:w-[43%] sm:px-0 sm:pl-10 md:w-[35%] lg:w-[47%] lg:pl-16">
                        <div className="mb-5">
                            {otherSubstancesAdd.map((o: OtherSubstance) => (
                                <p
                                    key={o.id}
                                    className={`${o.bold ? "font-semibold" : ""} text-base sm:text-md lg:text-lg`}
                                >
                                    {o.text}
                                </p>
                            ))}
                        </div>
                        <table className="text-base sm:text-md lg:text-lg">
                            <tbody>
                                {
                                    // @ts-ignore
                                    asteriskTable.map((row, i) => (
                                        <tr key={i} className="mb-5 flex last:mb-0 sm:mb-7">
                                            {
                                                // @ts-ignore
                                                row.map(item => (
                                                    <td
                                                        key={item.id}
                                                        className="align-top first:w-5 first:flex-shrink-0 first:text-md first:font-semibold sm:first:w-6.5"
                                                    >
                                                        {item.text}
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Spacer variant={!spacerBottom ? SpacerVariant.None : undefined} />
        </>
    )
}

NutritionTable.schema = {
    name: "NutritionTable",
    label: "Nutrition Table",
    repeaterItems: [
        {
            name: "centerText",
            items: [
                {
                    type: "CenterText",
                    label: "Text",
                    max: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): NutritionTableProps => ({
        spacerTop: true,
        spacerBottom: true,
    }),
}

export default NutritionTable
