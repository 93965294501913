import React, { useMemo } from "react"
import { Repeater, Text, types, useAdminContext } from "react-bricks/frontend"

import { ButtonBrickProps } from "../../button/Button"
import { ButtonSize } from "../../components/button"
import { EqualHeightElement } from "react-equal-height/clean"
import { LazyImage } from "../../components/universal"
import MatrixItemPrice from "./Price"
import { MatrixItemRowProps } from "./Row"
import { Product } from "@lavita-io/international-sdk"
import classNames from "classnames"
import { useLocalesState } from "../../../shared/context/locales"
import { useTranslation } from "react-i18next"
import { useUrlSearchParams } from "../../../shared/hooks/use-url-search-params"
import { useWindowSize } from "../../../hooks/use-window-size-admin"

export interface MatrixItemProps {
    sku?: string
    firstOnMobile?: string
    index: number
    products?: Product[]
    highlight?: boolean
    repeaterLength: number
    repeaterLengthFiltered: number
    changeTab: (sku?: string) => void
    activeTab: number
    showActiveOnly?: boolean
    repeater: {
        id: string
        props: MatrixItemRowProps
        type: "MatrixItemRow"
    }[]
    repeaterBottom: {
        id: string
        props: ButtonBrickProps
        type: "Button"
    }[]
}

const MatrixItem: types.Brick<MatrixItemProps> = ({
    sku,
    firstOnMobile,
    products,
    index,
    highlight,
    repeaterLength,
    repeaterLengthFiltered,
    changeTab,
    activeTab,
    showActiveOnly,
}) => {
    const { t } = useTranslation("translations")
    const { region, language } = useLocalesState()

    const product = products?.find(p => p.sku === sku)

    const { isAdmin } = useAdminContext()

    const { width } = useWindowSize({ initializeWithValue: false, debounceDelay: 200 })

    const row = useMemo(
        () =>
            Math.ceil(
                (1 / (repeaterLengthFiltered % 3 === 0 || (width && width >= 1366) ? 3 : 2)) *
                    ((index >= repeaterLength - 1 ? index - (repeaterLength - repeaterLengthFiltered) : index) + 1)
            ),
        [repeaterLengthFiltered, repeaterLength, index, width]
    )

    const active = useMemo(() => activeTab === index && width && width <= 767, [activeTab, index, width])

    const [params] = useUrlSearchParams()

    const hl = useMemo(() => (params.hl ? params.hl === sku : highlight), [params, highlight, sku])

    if (sku && !product && !isAdmin) return null

    return (
        <li
            key={index}
            id={sku}
            className={classNames(
                "lg:nth-last-2:nth-[3n+1]:col-end-4 lg:last:nth-[3n-1]:col-end-[-2] relative flex flex-col justify-end sm:col-span-2",
                firstOnMobile === sku && "order-first sm:order-none"
            )}
        >
            {hl && !showActiveOnly && (
                <div className="flex">
                    <span className="text-box absolute left-1/2 top-0 z-2 block -translate-x-1/2 -translate-y-1/2 rounded-full bg-light-blue px-2 py-1.5 text-[10px] font-semibold text-white xs:px-3 xs:text-xs sm:relative sm:left-7 sm:translate-x-0 sm:translate-y-0 sm:rounded-b-[0] sm:rounded-t-base sm:bg-gradient-to-r sm:from-[#254a76] sm:via-[#3b69a0] sm:to-[#254a76] sm:px-5 sm:py-2.5 sm:pb-1.5 sm:pt-2.5 sm:text-sm lg:px-7 lg:pt-3">
                        {t("product_matrix_highlight")}
                    </span>
                </div>
            )}
            <div
                className={classNames(
                    "overflow-hidden rounded-base sm:overflow-visible sm:rounded-lg sm:border sm:p-3",
                    hl ? "sm:border-blue sm:shadow-black-base" : "sm:border-border-tide",
                    !showActiveOnly && !active && "border border-border-tide",
                    !showActiveOnly && active && "shadow-black-base",
                    showActiveOnly && active && "py-4"
                )}
            >
                <button
                    type="button"
                    onClick={() => changeTab(sku)}
                    className={classNames(
                        "relative block w-full px-2.5 pt-6 text-left xs:px-3.5 sm:block sm:rounded-base sm:px-5 sm:pt-5 md:py-5.5 lg:py-8",
                        hl
                            ? "sm:bg-gradient-to-r sm:from-[#254a76] sm:via-[#3b69a0] sm:to-[#254a76] md:via-75%"
                            : "sm:bg-background-tide",
                        active && "bg-gradient-to-r from-[#254a76] via-[#3b69a0] to-[#254a76]",
                        !isAdmin && "sm:pointer-events-none",
                        showActiveOnly && "hidden"
                    )}
                    tabIndex={width && width > 767 ? -1 : undefined}
                >
                    <EqualHeightElement name={"top-" + row}>
                        <div className="flex h-full flex-col justify-between">
                            <div className="xs:mb-3.5 sm:mb-5 md:mb-0 md:max-w-[calc(100%-134px)] lg:max-w-[calc(100%-148px)]">
                                <Text
                                    propName="name"
                                    renderBlock={({ children }) => (
                                        <h4
                                            className={classNames(
                                                "mb-1 text-center text-sm font-semibold xs:text-base sm:text-left sm:text-lg lg:text-xl",
                                                {
                                                    "sm:text-white": hl,
                                                    "text-white": active,
                                                }
                                            )}
                                        >
                                            {children}
                                        </h4>
                                    )}
                                    placeholder="Lorem ipsum"
                                />
                                <Text
                                    propName="description"
                                    renderBlock={({ children }) => (
                                        <p
                                            className={classNames("hidden text-sm sm:block lg:text-md", {
                                                "sm:text-white": hl,
                                                "text-white": active,
                                            })}
                                        >
                                            {children}
                                        </p>
                                    )}
                                    placeholder="Lorem ipsum"
                                />
                            </div>
                            <div className="mx-auto w-[40px] xs:w-[50px] sm:w-[81px] md:absolute md:bottom-0 md:right-10 md:w-[104px] lg:w-[118px]">
                                <div className="aspect-h-5 aspect-w-4">
                                    <LazyImage
                                        path={
                                            product?.images
                                                .find(i => i.type === "shop")
                                                ?.href.replace(process.env.IMG_CDN_URL_ASSETS, "") || ""
                                        }
                                        srcSet={[
                                            { w: 40 },
                                            { w: 50 },
                                            { w: 81 },
                                            { w: 104 },
                                            { w: 80 },
                                            { w: 100 },
                                            { w: 118, default: true },
                                            { w: 162 },
                                            { w: 208 },
                                            { w: 236 },
                                        ]}
                                        sizes="(max-width: 389px) 40px, (max-width: 767px) 50px, (max-width: 1023px) 81px, (max-width: 1365px) 104px, 118px"
                                        className="block"
                                    />
                                </div>
                            </div>
                            {active && (
                                <div className="absolute bottom-3 right-3 flex h-5 w-5 items-center justify-center rounded-full bg-white sm:hidden">
                                    <i className="lavita-icon text-[10px] text-blue"></i>
                                </div>
                            )}
                        </div>
                    </EqualHeightElement>
                </button>
                <div className={classNames("sm:block sm:p-2 lg:p-5", !(showActiveOnly && active) && "hidden")}>
                    <Repeater propName="repeater" itemProps={{ row }} />
                    <MatrixItemPrice row={row} product={product} />
                    <Repeater propName="bottomRepeater" itemProps={{ fullWidth: true, size: ButtonSize.Small }} />
                    {["DE", "AT", "CH"].includes(region) && ["de", "en"].includes(language) && (
                        <p className="mt-3.5 text-center text-xs font-semibold text-swamp-green">
                            {t("product_matrix_shipping")}
                        </p>
                    )}
                </div>
            </div>
        </li>
    )
}

MatrixItem.schema = {
    name: "MatrixItem",
    label: "MatrixItem",
    hideFromAddMenu: true,
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "MatrixItemRow",
                    label: "Row",
                    min: 1,
                },
            ],
        },
        {
            name: "bottomRepeater",
            items: [
                {
                    type: "Button",
                    label: "Button",
                    min: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "sku",
            label: "SKU",
            type: types.SideEditPropType.Text,
        },
        {
            name: "highlight",
            label: "highlight",
            type: types.SideEditPropType.Boolean,
        },
    ],
}

export default MatrixItem
