import { ContainerBackgroundColor, ContainerVariant, containerBackgroundOptions } from "../components/layout"
import { Pictograms, pictogramOptions } from "../../types"
import { Repeater, types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../Typography"

import { Color } from "../components/typography"
import Icon from "../components/icon-text/icon"
import React from "react"
import { RoundedBackgroundContainer } from "../components/layout"
import { TextLinkBrickProps } from "../text/TextLink"
import { TextLinkSize } from "../components/text"
import { useCheckForEmptyText } from "../../hooks/use-check-for-empty-text"

export interface IconTextProps {
    icon: Pictograms
    background: { color: ContainerBackgroundColor } | ContainerBackgroundColor
    headline: TypographyElement[]
    text: TypographyElement[]
    variant?: ContainerVariant
    index?: number
}

const IconText: types.Brick<IconTextProps> = ({ icon, background, variant, headline, text, index }) => {
    const emptyHeadline = useCheckForEmptyText(headline)
    const emptyText = useCheckForEmptyText(text)

    const bgColor = typeof background === "object" ? background.color : background

    return (
        <li key={index}>
            <RoundedBackgroundContainer variant={variant} background={bgColor} className="flex h-full flex-col">
                <Icon icon={icon} />
                {!emptyHeadline && (
                    <Color>
                        <Typography propName="headline" allowList={["sup", "link", "bold"]} />
                    </Color>
                )}
                {!emptyText && (
                    <Color>
                        <Typography block="copytext-small" allowList={["small", "sup", "link", "bold"]} />
                    </Color>
                )}
                <Repeater propName="repeater" itemProps={{ size: TextLinkSize.Small } as TextLinkBrickProps} />
            </RoundedBackgroundContainer>
        </li>
    )
}

IconText.schema = {
    name: "IconText",
    label: "Icon Text",
    hideFromAddMenu: true,
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "TextLink",
                    label: "Text Link",
                    max: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "icon",
            label: "Icon",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: pictogramOptions,
                display: types.OptionsDisplay.Select,
            },
        },
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: containerBackgroundOptions,
                display: types.OptionsDisplay.Color,
            },
        },
    ],
    getDefaultProps: (): IconTextProps => ({
        icon: Pictograms.vitality,
        background: { color: ContainerBackgroundColor.Grey },
        headline: [
            {
                type: "paragraph",
                children: [
                    {
                        bold: true,
                        text: "Lorem ipsum dolor sit amet.",
                    },
                ],
            },
        ],
        text: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc placerat sagittis faucibus.",
                    },
                ],
            },
        ],
    }),
}

export default IconText
