import React, { useMemo } from "react"

import { Product } from "@lavita-io/international-sdk"
import { formatPrice } from "../../../shared/utils/format"
import { useLocalesState } from "../../../shared/context/locales"

export interface UpsellingItemPriceProps {
    product?: Product
}

const getPrice = (product: Product, language: string) => {
    return product.sku === "LV2START"
        ? formatPrice(product.price.amount / (product.splitted?.parts || 1), product.price.currency, language)
        : product.sku === "LVSTARTNA"
        ? formatPrice(product.price.amount / 3, product.price.currency, language)
        : product.subscription
        ? formatPrice(
              (product.price.amount * (product.subscription.max_quantity - 1)) / product.subscription.max_quantity,
              product.price.currency,
              language
          ) + "*"
        : formatPrice(product.price.amount, product.price.currency, language)
}

const UpsellingItemPrice = ({ product }: UpsellingItemPriceProps) => {
    const { language } = useLocalesState()

    if (!product) return null

    const price = useMemo(() => getPrice(product, language), [product, language])

    return <h4 className="text-right text-base font-semibold sm:text-md lg:text-lg">{price}</h4>
}

export default UpsellingItemPrice
