import BiathlonFormStyles from "./biathlon.module.css"
import Checkbox from "../../../shared/components/fields/checkbox"
import FlexWrapper from "../../../shared/components/fields/flex-wrapper"
import IconAction from "../../../shared/components/universal/icon-action"
import Input from "../../../shared/components/fields/input"
import LinkButtonWrapper from "../universal/link-button-wrapper"
import Radio from "../../../shared/components/fields/radio"
import React from "react"
import { genders } from "../../../shared/config/genders"
import { toast } from "react-toastify"
import { useForm } from "react-hook-form"
import { useState } from "react"
import { useTranslation } from "react-i18next"

/* global exponea */

const BiathlonForm24 = () => {
    const { t } = useTranslation("translations")

    const [isSuccess, setIsSuccess] = useState(false)

    const {
        handleSubmit,
        control,
        reset,
        register,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
        defaultValues: {
            gender: "",
            firstname: "",
            lastname: "",
            email: "",
        },
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
    })

    const submit = values => {
        const wrapper = document.getElementById("wrapper")

        if (exponea.isLoaded) {
            exponea.track("biathlon_gewinnspiel_2024", {
                action: "new",
                salutation: values.gender,
                firstname: values.firstname,
                lastname: values.lastname,
                email: values.email,
                checkbox: false,
            })

            toast.success(t("advent_calender_form_success"), { autoClose: 5000 })
            reset()
            setIsSuccess(true)
            wrapper.scrollIntoView({ behavior: "smooth", block: "center" })
        } else {
            toast.error(t("advent_calender_form_error"), { autoClose: 5000 })
            setIsSuccess(false)
        }
    }

    return (
        <div className={BiathlonFormStyles.wrapper} id="wrapper">
            {!isSuccess ? (
                <form onSubmit={handleSubmit(submit)} name="biathlon" className={BiathlonFormStyles.form}>
                    <h3>{t("biathlon_heading_contact")}</h3>
                    <Radio
                        register={register("gender", {
                            required: { value: true, message: t("form_error_required") },
                        })}
                        error={errors}
                        options={genders.map(g => ({
                            label: t(g.name),
                            value: g.id,
                        }))}
                        flex
                        label={t("biathlon_form_gender_label")}
                        required
                    />
                    <FlexWrapper flex={["2 1 auto", "2 1 auto"]} wrap>
                        <Input
                            control={control}
                            rules={{ required: { value: true, message: t("form_error_required") } }}
                            type="text"
                            name="firstname"
                            label={t("contact_form_firstname_label")}
                            autocomplete="given-name"
                            required
                        />
                        <Input
                            control={control}
                            rules={{ required: { value: true, message: t("form_error_required") } }}
                            type="text"
                            name="lastname"
                            label={t("contact_form_lastname_label")}
                            autocomplete="family-name"
                            required
                        />
                    </FlexWrapper>
                    <Input
                        control={control}
                        rules={{ required: { value: true, message: t("form_error_required") } }}
                        type="email"
                        name="email"
                        label={t("contact_form_email_label")}
                        autocomplete="email"
                        required
                    />
                    <div className={BiathlonFormStyles.checkbox}>
                        <Checkbox
                            register={register("checkbox", {
                                required: { value: true, message: t("form_error_required") },
                            })}
                            error={errors}
                            label={t("biathlon_form_label_checkbox")}
                        />
                    </div>
                    <div className={BiathlonFormStyles.footnote}>
                        <span className={BiathlonFormStyles.required}>* </span>
                        {t("biathlon_form_mandatory_field")}
                    </div>
                    <LinkButtonWrapper center>
                        <IconAction title={t("biathlon_form_submit")} button submit />
                    </LinkButtonWrapper>
                </form>
            ) : (
                <div className={BiathlonFormStyles.successWrapper}>
                    <div className={BiathlonFormStyles.success}>
                        <div className={BiathlonFormStyles.circle}>
                            <i className="lavita-icon"></i>
                        </div>
                        <div className={BiathlonFormStyles.successText}>{t("biathlon_form_success_box_text")}</div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default BiathlonForm24
