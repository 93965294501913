import { Grid, GridColumns, Spacer, SpacerVariant } from "../components/layout"
import { Pictograms, pictogramOptions } from "../../types"
import Typography, { TypographyElement } from "../Typography"

import { Color } from "../components/typography"
import { IconText } from "../components/icon-text"
import React from "react"
import { types } from "react-bricks/frontend"

export interface IconTwoColumnsTextProps {
    headlineItem: TypographyElement[]
    headline: TypographyElement[]
    headline2: TypographyElement[]
    text: TypographyElement[]
    text2: TypographyElement[]
    index?: number
    icon: Pictograms
}

const IconTwoColumnsText: types.Brick<IconTwoColumnsTextProps> = ({ icon }) => {
    return (
        <li className="space-y-4 sm:space-y-5">
            <IconText icon={icon} />
            <Typography propName="headlineItem" allowList={["bold", "h5"]} />
            <Grid columns={GridColumns.Two}>
                <Color>
                    <Typography propName="headline" allowList={["sup", "link", "bold", "h3"]} />
                    <Typography block="copytext-small" allowList={["small", "bold", "sup", "link"]} />
                </Color>
                <Color>
                    <Typography propName="headline2" allowList={["sup", "link", "bold", "h3"]} />
                    <Typography block="copytext-small" allowList={["small", "bold", "sup", "link"]} propName="text2" />
                </Color>
            </Grid>
            <Spacer variant={SpacerVariant.Small} />
        </li>
    )
}

IconTwoColumnsText.schema = {
    name: "IconTwoColumnsText",
    label: "Icon 2 Columns Text",
    hideFromAddMenu: true,
    sideEditProps: [
        {
            name: "icon",
            label: "Icon",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: pictogramOptions,
                display: types.OptionsDisplay.Select,
            },
        },
    ],
    getDefaultProps: (): IconTwoColumnsTextProps => ({
        icon: Pictograms.bloodsugar,
        headlineItem: [
            {
                type: "h5",
                children: [
                    {
                        bold: true,
                        text: "Lorem ipsum dolor sit amet.",
                    },
                ],
            },
        ],
        headline: [
            {
                type: "paragraph",
                children: [
                    {
                        bold: true,
                        text: "dolor sit amet.Lorem ipsum",
                    },
                ],
            },
        ],
        headline2: [
            {
                type: "paragraph",
                children: [
                    {
                        bold: true,
                        text: "dolor sit amet.Lorem ipsum",
                    },
                ],
            },
        ],
        text: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc placerat sagittis faucibus.",
                    },
                ],
            },
        ],
        text2: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc placerat sagittis faucibus.",
                    },
                ],
            },
        ],
    }),
}

export default IconTwoColumnsText
