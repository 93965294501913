import ImageEditor, { AspectRatio } from "../Image"
import { Repeater, types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../Typography"

import { Color } from "../components/typography"
import React from "react"
import { TextLinkBrickProps } from "../text/TextLink"
import { TextLinkSize } from "../components/text"
import classNames from "classnames"

export interface ThreeColumnBigIconTextItemProps {
    text: TypographyElement[]
    index?: number
    centerTextImage?: boolean
    hideTextMobile?: boolean
    smallIconMobile?: boolean
}

const ThreeColumnBigIconTextItem: types.Brick<ThreeColumnBigIconTextItemProps> = ({ index, centerTextImage, hideTextMobile, smallIconMobile }) => {
    return (
        <li
            key={index}
            className={classNames("flex flex-col items-center space-y-[14px] sm:space-y-5 lg:space-y-7", {
                "sm:items-start": !centerTextImage,
            })}
        >
            <ImageEditor
                aspectRatio={AspectRatio["1/1"]}
                maxWidth={250}
                className={classNames("md:max-w-[200px] lg:max-w-[250px]", {
                    "max-w-[150px]": !smallIconMobile,
                    "max-w-[100px] sm:max-w-[150px]": smallIconMobile,
                })}
            />
            <div
                className={classNames("space-y-3.5 text-center sm:space-y-5", {
                    "sm:text-left": !centerTextImage,
                    "hidden sm:block": hideTextMobile,
                })}
            >
                <Color>
                    <Typography block="copytext-small" allowList={["h5", "small", "sup", "link", "bold"]} />
                </Color>
                <Repeater propName="repeater" itemProps={{ size: TextLinkSize.Small } as TextLinkBrickProps} />
            </div>
        </li>
    )
}

ThreeColumnBigIconTextItem.schema = {
    name: "ThreeColumnBigIconTextItem",
    label: "3 Column Big Icon Text Item",
    hideFromAddMenu: true,
    sideEditProps: [
        {
            name: "centerTextImage",
            label: "Center Text Image",
            type: types.SideEditPropType.Boolean,
        },
    ],
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "TextLink",
                    label: "Text Link",
                    max: 1,
                },
            ],
        },
    ],
    getDefaultProps: (): ThreeColumnBigIconTextItemProps => ({
        centerTextImage: false,
        smallIconMobile: false,
        hideTextMobile: false,
        text: [
            {
                type: "h5",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consetetur",
                        bold: true,
                    },
                ],
            },
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
                    },
                ],
            },
        ],
    }),
}

export default ThreeColumnBigIconTextItem
