import React, { useMemo } from "react"

import { Product } from "@lavita-io/international-sdk"
import { formatPrice } from "../../../shared/utils/format"
import i18n from "../../../i18n/config"
import { useLocalesState } from "../../../shared/context/locales"

export interface UpsellingPriceAsteriskProps {
    product?: Product
}

const getAsterisk = (product: Product, language: string) => {
    return product.subscription
        ? i18n.t("upselling_price_per_litre_subscription_asterisk", {
              amount: product.subscription.max_quantity,
              price: formatPrice(product.price.amount, product.price.currency, language),
              discount_price: formatPrice(
                  (product.price.amount * (product.subscription.max_quantity - 1)) / product.subscription.max_quantity,
                  product.price.currency,
                  language
              ),
          })
        : null
}

const UpsellingPriceAsterisk = ({ product }: UpsellingPriceAsteriskProps) => {
    const { language } = useLocalesState()

    if (!product) return null

    const asterisk = useMemo(() => getAsterisk(product, language), [product, language])

    return <p className="text-sm font-semibold sm:text-base lg:text-md">{asterisk}</p>
}

export default UpsellingPriceAsterisk
