import { cs, da, de, enGB, es, fr, it, nb, nl, pl, sv, tr } from "date-fns/locale"

const allLanguages = [
    {
        id: 0,
        langCode: "de",
        name: "Deutsch",
        "date-fns": de,
        dateFormat: "dd.MM.yyyy",
        ccmLang: "de_DE",
    },
    {
        id: 1,
        langCode: "en",
        name: "English",
        "date-fns": enGB,
        dateFormat: "dd/MM/yyyy",
        ccmLang: "en_US",
    },
    {
        id: 2,
        langCode: "nl",
        name: "Nederlands",
        "date-fns": nl,
        dateFormat: "dd-MM-yyyy",
        ccmLang: "en_US",
    },
    {
        id: 3,
        langCode: "fr",
        name: "Français",
        "date-fns": fr,
        dateFormat: "dd/MM/yyyy",
        ccmLang: "en_US",
    },
    {
        id: 4,
        langCode: "cs",
        name: "Čeština",
        "date-fns": cs,
        dateFormat: "dd.MM.yyyy",
        ccmLang: "en_US",
    },
    {
        id: 5,
        langCode: "da",
        name: "Dansk",
        "date-fns": da,
        dateFormat: "dd.MM.yyyy",
        ccmLang: "en_US",
    },
    {
        id: 6,
        langCode: "es",
        name: "Español",
        "date-fns": es,
        dateFormat: "dd/MM/yyyy",
        ccmLang: "en_US",
    },
    {
        id: 7,
        langCode: "it",
        name: "Italiano",
        "date-fns": it,
        dateFormat: "dd/MM/yyyy",
        ccmLang: "en_US",
    },
    {
        id: 8,
        langCode: "no",
        name: "Bokmål",
        "date-fns": nb,
        dateFormat: "dd.MM.yyyy",
        ccmLang: "en_US",
    },
    {
        id: 9,
        langCode: "sv",
        name: "Svenska",
        "date-fns": sv,
        dateFormat: "dd.MM.yyyy",
        ccmLang: "en_US",
    },
    {
        id: 10,
        langCode: "tr",
        name: "Türkçe",
        "date-fns": tr,
        dateFormat: "dd.MM.yyyy",
        ccmLang: "en_US",
    },
    {
        id: 11,
        langCode: "pl",
        name: "Polski",
        "date-fns": pl,
        dateFormat: "dd.MM.yyyy",
        ccmLang: "en_US",
    },
]

const languages = allLanguages.filter(l => process.env.LANGUAGES.includes(l.langCode))

export { allLanguages, languages }
