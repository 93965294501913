import React from "react"
import { types } from "react-bricks/frontend"
import { Background, BackgroundColor, backgroundOptions, Container, Spacer, SpacerVariant } from "../components/layout"
import { options } from "../../types"
import { AspectRatio } from "../video/FullWidthVideoBreak"
import ImageEditor from "../Image"
import classNames from "classnames"
import { AspectRatio as ImageAspectRatio } from "../Image"

export enum Grid {
    "1 Image" = "grid-cols-1",
    "2 Images" = "grid-cols-2",
}

interface BasicImageProps {
    background: { color: BackgroundColor } | BackgroundColor
    spacerTop: boolean
    spacerBottom: boolean
    grid: Grid
    aspectRatio: AspectRatio
}

const BasicImage: types.Brick<BasicImageProps> = ({ background, spacerTop, spacerBottom, grid, aspectRatio }) => {
    const bgColor = typeof background === "object" ? background.color : background

    return (
        <Background color={bgColor}>
            <Container>
                <Spacer variant={!spacerTop ? SpacerVariant.None : SpacerVariant.Small} />
                <div
                    className={classNames("grid w-full", grid, {
                        "mx-auto w-full gap-1.5 sm:w-[80%] sm:gap-3 md:gap-3.5 lg:gap-5 xl:gap-6":
                            grid === Grid["2 Images"],
                    })}
                >
                    <ImageEditor
                        aspectRatio={grid === Grid["1 Image"] ? aspectRatio : ImageAspectRatio["4/5"]}
                        maxWidth={grid === Grid["1 Image"] ? 1440 : 564}
                        className="w-full rounded-base"
                        propName="image1"
                    />
                    <ImageEditor
                        aspectRatio={ImageAspectRatio["4/5"]}
                        maxWidth={564}
                        className={classNames("w-full rounded-base", { hidden: grid === Grid["1 Image"] })}
                        propName="image2"
                    />
                </div>
                <Spacer variant={!spacerBottom ? SpacerVariant.None : SpacerVariant.Small} />
            </Container>
        </Background>
    )
}

BasicImage.schema = {
    name: "BasicImage",
    label: "Basic Image",
    sideEditProps: [
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: backgroundOptions,
                display: types.OptionsDisplay.Color,
            },
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
        {
            name: "grid",
            label: "Grid",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: options<typeof Grid>(Grid),
                display: types.OptionsDisplay.Select,
            },
        },
        {
            name: "aspectRatio",
            label: "Aspect Ratio",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: options<typeof AspectRatio>(AspectRatio),
                display: types.OptionsDisplay.Select,
            },
            show: props => props.grid === Grid["1 Image"],
        },
    ],
    getDefaultProps: (): BasicImageProps => ({
        background: { color: BackgroundColor.White },
        spacerTop: true,
        spacerBottom: true,
        aspectRatio: AspectRatio["5/2"],
        grid: Grid["1 Image"],
    }),
}

export default BasicImage
