import React from "react"
import {
    Background,
    BackgroundColor,
    backgroundOptions,
    Container,
    Spacer,
    SpacerVariant,
} from "../../components/layout"
import { Repeater, types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../../Typography"
import { useCheckForEmptyText } from "../../../hooks/use-check-for-empty-text"
import { Color, TypographyColor } from "../../components/typography"

interface ThreeColumnImageListProps {
    background: { color: BackgroundColor } | BackgroundColor
    spacerTop: boolean
    spacerBottom: boolean
    headline: TypographyElement[]
}

const ThreeColumnImageList: types.Brick<ThreeColumnImageListProps> = ({
    background,
    spacerTop,
    spacerBottom,
    headline,
}) => {
    const bgColor = typeof background === "object" ? background.color : background

    const emptyHeadline = useCheckForEmptyText(headline)

    return (
        <Background color={bgColor}>
            <Container>
                <Spacer variant={!spacerTop ? SpacerVariant.None : SpacerVariant.Small} />
                <Color
                    color={
                        bgColor === BackgroundColor.Green || bgColor === BackgroundColor.DarkBlumine
                            ? TypographyColor.White
                            : TypographyColor.Darkgrey
                    }
                >
                    {!emptyHeadline && (
                        <Typography
                            allowList={["h3", "h4", "small", "sup", "link", "bold", "centeredCheckList"]}
                            propName="headline"
                        />
                    )}
                    <ul className="mt-5 grid grid-cols-1 gap-x-5 gap-y-3 sm:mt-7 sm:grid-cols-3 sm:gap-y-7 md:gap-x-7 md:gap-y-10 lg:mt-10 lg:gap-x-5 xl:gap-x-16 xl:gap-y-16">
                        <Repeater propName="repeater" />
                    </ul>
                </Color>
                <Spacer variant={!spacerBottom ? SpacerVariant.None : SpacerVariant.Small} />
            </Container>
        </Background>
    )
}

ThreeColumnImageList.schema = {
    name: "ThreeColumnImageList",
    label: "3 Column Image List",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "ThreeColumnImageListItem",
                    label: "Image List Item",
                    min: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: backgroundOptions,
                display: types.OptionsDisplay.Color,
            },
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): ThreeColumnImageListProps => ({
        background: { color: BackgroundColor.White },
        spacerTop: true,
        spacerBottom: true,
        headline: [
            {
                type: "h4",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet",
                        bold: true,
                    },
                ],
            },
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc placerat sagittis faucibus.",
                    },
                ],
            },
        ],
    }),
}

export default ThreeColumnImageList
