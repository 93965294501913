import React, { useState } from "react"

import EdelstahlFlascheStyles from "./edelstahl-flasche.module.css"
import FlexWrapper from "../../../shared/components/fields/flex-wrapper"
import IconAction from "../../../shared/components/universal/icon-action"
import Input from "../../../shared/components/fields/input"
import Radio from "../../../shared/components/fields/radio"
import Spacer from "../universal/spacer"
import Text from "../../../components-v2/new/matrix/text"
import WrapWithError from "../../../shared/components/fields/wrap-with-error"
import classNames from "classnames"
import { genders } from "../../../shared/config/genders"
import { languages } from "../../../shared/config/languages"
import { scrollTo } from "../../../shared/utils/smooth-scroll"
import { toast } from "react-toastify"
import { useCheckCartCoupon } from "../../../shared/hooks/cart/use-query-cart"
import { useDimensions } from "../../../shared/hooks/use-dimensions"
import { useForm } from "react-hook-form"
import { useLocalesState } from "../../../shared/context/locales"
import { usePostContact } from "../../../hooks/contact/use-query-contact"
import { useTranslation } from "react-i18next"

const EdelstahlFlasche = props => {
    const { t } = useTranslation("translations")
    const [valid, setValid] = useState(false)
    const [sentForm, setSentForm] = useState(false)
    const { region } = useLocalesState()

    const {
        formState: { errors },
        handleSubmit,
        register,
        setValue,
        control,
        reset,
    } = useForm({
        mode: "onTouched",
        defaultValues: {
            salutation: genders[0].id,
            firstname: "",
            lastname: "",
            email: "",
            tel: "",
            street: "",
            postcode: "",
            city: "",
            code: "",
        },
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
    })

    const mutate = usePostContact()
    const { width } = useDimensions()
    const checkCartCoupon = useCheckCartCoupon()

    const submit = async values => {
        if (!valid) {
            try {
                const response = await checkCartCoupon.mutateAsync({
                    code: values.code,
                    country: languages[0].langCode.toUpperCase(),
                })
                if (response) {
                    setValid(true)
                } else {
                    toast.error(t("edelstahl_coupon_code_invalid"))
                }
            } catch (error) {
                return
            }
        } else {
            const body = {
                category_id: process.env.CONTACT_BGM_CATEGORY_ID,
                firstname: values.firstname,
                lastname: values.lastname,
                email: values.email,
                phone: values.tel,
                body: t("edelstahl_form_submit_body", {
                    salutation: values.salutation,
                    firstname: values.firstname,
                    lastname: values.lastname,
                    street: values.street,
                    postcode: values.postcode,
                    city: values.city,
                    email: values.email,
                    phone: values.tel,
                    code: values.code,
                }),
            }

            try {
                await mutate.mutateAsync(body, { throwError: true })
                reset()
                setSentForm(true)
                scrollTo("success_message")
            } catch (error) {
                return
            }
        }
    }

    return (
        <div className={EdelstahlFlascheStyles.wrapper}>
            <form
                onSubmit={handleSubmit(submit)}
                name="EdelstahlFlasche"
                className={classNames(EdelstahlFlascheStyles.form, { [EdelstahlFlascheStyles.inline]: props.inline })}
            >
                {valid ? (
                    <>
                        {!sentForm ? (
                            <>
                                <Text small source={t("edelstahl_form_title")} />
                                <Spacer small />
                                <Radio
                                    register={register("salutation", {
                                        required: { value: true, message: t("form_error_required") },
                                    })}
                                    error={errors}
                                    label={t("partner_form_salutation_label")}
                                    flex
                                    options={genders.map(g => ({
                                        label: t(g.name),
                                        value: g.id,
                                    }))}
                                    required
                                />
                                <FlexWrapper flex={["2 1 auto", "2 1 auto"]} wrap>
                                    <Input
                                        control={control}
                                        rules={{
                                            required: { value: true, message: t("partner_form_firstname_required") },
                                        }}
                                        type="text"
                                        name="firstname"
                                        label={t("partner_form_firstname_label")}
                                        autocomplete="given-name"
                                        required
                                    />
                                    <Input
                                        control={control}
                                        rules={{
                                            required: { value: true, message: t("partner_form_lastname_required") },
                                        }}
                                        type="text"
                                        name="lastname"
                                        label={t("partner_form_lastname_label")}
                                        autocomplete="family-name"
                                        required
                                    />
                                </FlexWrapper>
                                <Input
                                    control={control}
                                    rules={{ required: { value: true, message: t("partner_form_street_required") } }}
                                    type="text"
                                    name="street"
                                    label={t("partner_form_street_label")}
                                    required
                                />
                                <FlexWrapper childWidths={width > 767 ? [30, 70] : [100, 100]} wrap>
                                    <Input
                                        control={control}
                                        rules={{
                                            required: { value: true, message: t("partner_form_postcode_required") },
                                            pattern: {
                                                value: region === "AT" ? /\b[0-9]{4}\b/ : /\b[0-9]{5}\b/,
                                                message: t("partner_form_postcode_pattern"),
                                            },
                                        }}
                                        type="tel"
                                        name="postcode"
                                        label={t("partner_form_postcode_label")}
                                        length={region === "AT" ? 4 : 5}
                                        required
                                    />
                                    <Input
                                        control={control}
                                        rules={{ required: { value: true, message: t("partner_form_city_required") } }}
                                        type="text"
                                        name="city"
                                        label={t("partner_form_city_label")}
                                        required
                                    />
                                </FlexWrapper>
                                <Input
                                    control={control}
                                    rules={{ required: { value: false, message: t("partner_form_email_required") } }}
                                    type="email"
                                    name="email"
                                    label={t("partner_form_email_label")}
                                    autocomplete="email"
                                    required
                                />
                                <div className={EdelstahlFlascheStyles.emailSmallWrapper}>
                                    <small dangerouslySetInnerHTML={{ __html: t("edelstahl_email_legal") }} />
                                </div>
                                <Input
                                    control={control}
                                    rules={{ required: { value: false, message: t("partner_form_phone_required") } }}
                                    type="tel"
                                    name="tel"
                                    label={t("partner_form_phone_label")}
                                    autocomplete="tel"
                                />

                                <small
                                    dangerouslySetInnerHTML={{ __html: t("edelstahl_legal") }}
                                    className={EdelstahlFlascheStyles.emailSmall}
                                />
                                <Spacer small />
                                <div className={EdelstahlFlascheStyles.submitWrapper}>
                                    <IconAction title={t("edelstahl_coupon_form_submit")} button submit />
                                </div>
                            </>
                        ) : (
                            <div className={EdelstahlFlascheStyles.success} id="success_message">
                                <div className={EdelstahlFlascheStyles.circle}>
                                    <i className="lavita-icon"></i>
                                </div>
                                <div className={EdelstahlFlascheStyles.successText}>{t("edelstahl_form_success")}</div>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div>
                            <Text small source={t("edelstahl_form_title_voucher")} />
                            <Spacer small />
                            <WrapWithError error={errors?.["code"]}>
                                <div className={EdelstahlFlascheStyles.inputWrapper}>
                                    <input
                                        {...register("code", {
                                            required: { value: true, message: t("form_error_required") },
                                        })}
                                        type="text"
                                        id="code"
                                        placeholder={props.inputPlaceholder || t("new_coupon_form_code_label")}
                                        className={classNames(EdelstahlFlascheStyles.input, {
                                            [EdelstahlFlascheStyles.error]: errors?.["code"],
                                            [EdelstahlFlascheStyles.withoutLabel]: props.inputLabel === false,
                                        })}
                                        required
                                    />
                                    {props.inputLabel !== false && (
                                        <label htmlFor="code" className={EdelstahlFlascheStyles.label}>
                                            <i className="lavita-icon"></i>
                                        </label>
                                    )}
                                </div>
                            </WrapWithError>
                        </div>
                        <Spacer small />
                        <div className={EdelstahlFlascheStyles.button_wrapper}>
                            <IconAction title={t("edelstahl_coupon_form_submit")} button submit />
                        </div>
                    </>
                )}
            </form>
        </div>
    )
}

export default EdelstahlFlasche
