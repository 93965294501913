import {
    Background,
    BackgroundColor,
    Container,
    Grid,
    GridColumns,
    Spacer,
    SpacerVariant,
    backgroundOptions,
} from "../components/layout"
import { Repeater, types } from "react-bricks/frontend"

import { CenterTextProps } from "../text/CenterText"
import { ImageTextColumnItemProps } from "./ImageTextColumnItem"
import React from "react"
import { TypographyColor } from "../components/typography"

interface ImageTextColumnProps {
    background: { color: BackgroundColor } | BackgroundColor
    spacerTop: boolean
    spacerBottom: boolean
    allwaysColumns: boolean
}

const ImageTextColumn: types.Brick<ImageTextColumnProps> = ({
    background,
    spacerTop,
    spacerBottom,
    allwaysColumns,
}) => {
    const bgColor = typeof background === "object" ? background.color : background

    return (
        <Background color={bgColor}>
            <Spacer variant={!spacerTop ? SpacerVariant.None : undefined} />
            <Container>
                <Repeater
                    propName="centerText"
                    itemProps={
                        {
                            color:
                                bgColor === BackgroundColor.Green || bgColor === BackgroundColor.DarkBlumine
                                    ? TypographyColor.White
                                    : TypographyColor.Darkgrey,
                        } as CenterTextProps
                    }
                />
                <Grid columns={GridColumns.Four} allwaysColumns={allwaysColumns} as="ul">
                    <Repeater
                        propName="repeater"
                        itemProps={
                            {
                                color:
                                    bgColor === BackgroundColor.Green || bgColor === BackgroundColor.DarkBlumine
                                        ? TypographyColor.White
                                        : TypographyColor.Darkgrey,
                            } as ImageTextColumnItemProps
                        }
                    />
                </Grid>
            </Container>
            <Spacer variant={!spacerBottom ? SpacerVariant.None : undefined} />
        </Background>
    )
}

ImageTextColumn.schema = {
    name: "ImageTextColumn",
    label: "Image Text Column",
    repeaterItems: [
        {
            name: "centerText",
            items: [
                {
                    type: "CenterText",
                    label: "Text",
                    max: 1,
                },
            ],
        },
        {
            name: "repeater",
            items: [
                {
                    type: "ImageTextColumnItem",
                    label: "Image Text Item",
                    min: 1,
                    max: 4,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "background",
            label: "background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                display: types.OptionsDisplay.Color,
                options: backgroundOptions,
            },
        },
        {
            name: "allwaysColumns",
            label: "Mobile Two Columns",
            type: types.SideEditPropType.Boolean,
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): ImageTextColumnProps => ({
        background: { color: BackgroundColor.White },
        spacerTop: true,
        spacerBottom: true,
        allwaysColumns: true,
    }),
}

export default ImageTextColumn
