import React, { useMemo } from "react"

import { Product } from "@lavita-io/international-sdk"
import { formatPrice } from "../../../shared/utils/format"
import i18n from "../../../i18n/config"
import { useLocalesState } from "../../../shared/context/locales"

export interface UpsellingBadgeTextProps {
    product?: Product
}

const getBadgeText = (product: Product, language: string) => {
    return product.subscription
        ? i18n.t("upselling_badge_subscription", {
              saved_price: formatPrice(
                  Math.floor(
                      (product.price.amount * product.subscription.max_quantity) / product.subscription.max_quantity -
                          (product.price.amount * (product.subscription.max_quantity - 1)) /
                              product.subscription.max_quantity
                  ),
                  product.price.currency,
                  language
              ),
          })
        : null
}

const UpsellingBadgeText = ({ product }: UpsellingBadgeTextProps) => {
    const { language } = useLocalesState()

    if (!product) return null

    const badgeText = useMemo(() => getBadgeText(product, language), [product, language])

    return (
        <p className="text-box w-fit rounded-full bg-orange px-3.5 py-1.5 text-sm font-semibold text-white lg:text-base">
            {badgeText}
        </p>
    )
}

export default UpsellingBadgeText
