import React, { useMemo } from "react"

import { Product } from "@lavita-io/international-sdk"
import { formatPrice } from "../../../shared/utils/format"
import i18n from "../../../i18n/config"
import { useLocalesState } from "../../../shared/context/locales"

export interface UpsellingSmallprintProps {
    product?: Product
}

const getSmallprint = (product: Product, language: string) => {
    return !["Liter", "litre"].includes(product.basic_price.unit)
        ? i18n.t("price_incl_vat")
        : product.subscription
        ? i18n.t("upselling_price_per_litre_subscription", {
              basic_price: formatPrice(product.basic_price.amount, product.price.currency, language),
              amount: product.subscription.max_quantity,
              price: formatPrice(product.price.amount, product.price.currency, language),
              discount_price: formatPrice(
                  (product.basic_price.amount * (product.subscription.max_quantity - 1)) /
                      product.subscription.max_quantity,
                  product.price.currency,
                  language
              ),
          })
        : i18n.t("price_per_litre_without_vat", {
              price: formatPrice(product.basic_price.amount, product.price.currency, language),
          })
}

const UpsellingSmallprint = ({ product }: UpsellingSmallprintProps) => {
    const { language } = useLocalesState()

    if (!product) return null

    const smallprint = useMemo(() => getSmallprint(product, language), [product, language])

    return <small className="text-xs text-dark-grey">{smallprint}</small>
}

export default UpsellingSmallprint
