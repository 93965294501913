import { Container, Spacer, SpacerVariant } from "../components/layout"
import ImageEditor, { AspectRatio } from "../Image"
import { Repeater, types } from "react-bricks/frontend"

import { VideoPlayer as Player } from "../components/video"
import React from "react"

interface VideoPlayerProps {
    cloudflareVideoId: string
    spacerTop: boolean
    spacerBottom: boolean
    isDecorativeVideo?: boolean
}

const VideoPlayer: types.Brick<VideoPlayerProps> = ({
    cloudflareVideoId,
    spacerTop,
    spacerBottom,
    isDecorativeVideo,
}) => {
    return (
        <Container>
            <Spacer variant={!spacerTop ? SpacerVariant.None : undefined} />
            <Repeater propName="centerText" />
            <Player cloudflareVideoId={cloudflareVideoId} isDecorativeVideo={isDecorativeVideo}>
                <ImageEditor
                    aspectRatio={AspectRatio["16/9"]}
                    maxWidth={1440}
                    propName="image"
                    className="w-full overflow-hidden rounded-base"
                />
            </Player>
            <Spacer variant={!spacerBottom ? SpacerVariant.None : undefined} />
        </Container>
    )
}

VideoPlayer.schema = {
    name: "VideoPlayer",
    label: "Video Player",
    repeaterItems: [
        {
            name: "centerText",
            items: [
                {
                    type: "CenterText",
                    label: "Text",
                    max: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "cloudflareVideoId",
            label: "Cloudflare Video Id",
            type: types.SideEditPropType.Text,
        },
        {
            name: "isDecorativeVideo",
            label: "Is Decorative Video",
            type: types.SideEditPropType.Boolean,
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): VideoPlayerProps => ({
        cloudflareVideoId: "",
        spacerTop: true,
        spacerBottom: true,
        isDecorativeVideo: false,
    }),
}

export default VideoPlayer
