import "./base.css"

import React, { useCallback, useRef, useState } from "react"

import SelectStyles from "./select.module.css"
import WrapWithError from "./wrap-with-error"
import classNames from "classnames"
import { useController } from "react-hook-form"
import { useOnClickOutside } from "../../hooks/use-on-click-outside"

const Select = ({
    name,
    control,
    rules,
    defaultValue,
    shouldUnregister,
    options,
    height,
    maxHeight,
    placeholder,
    width,
    variant,
    autoSize,
    label,
    onChange: onChangeProp,
    required,
}) => {
    const {
        field: { onChange, value, ref, ...rest },
        fieldState: { error },
    } = useController({
        name,
        control,
        rules,
        defaultValue,
        shouldUnregister,
    })

    const [inFocus, setInFocus] = useState(null)
    const [active, setActive] = useState(placeholder ? undefined : 0)
    const optionsRef = useRef(null)
    const blockOnMouseOver = useRef(false)

    const scrollIntoView = useCallback(index => {
        const optionsEl = optionsRef.current
        const focusedEl = Array.from(optionsEl.children)[index]
        const optionsRec = optionsEl.getBoundingClientRect()
        const focusedRect = focusedEl.getBoundingClientRect()
        const overScroll = focusedEl.offsetHeight / 3

        if (focusedRect.bottom + overScroll > optionsRec.bottom) {
            optionsEl.scrollTo(
                0,
                Math.min(
                    focusedEl.offsetTop + focusedEl.clientHeight - optionsEl.offsetHeight + overScroll,
                    optionsEl.scrollHeight
                )
            )
        } else if (focusedRect.top - overScroll < optionsRec.top) {
            optionsEl.scrollTo(0, Math.max(focusedEl.offsetTop - overScroll, 0))
        }
    }, [])

    const focusValue = useCallback(
        direction => {
            let index = active
            switch (direction) {
                case "next":
                    index += 1
                    if (index >= options.length) index = 0
                    break
                case "previous":
                    index -= 1
                    if (index < 0) index = options.length - 1
                    break
                default:
                    break
            }
            setActive(index)
            scrollIntoView(index)
        },
        [setActive, options, active, scrollIntoView]
    )

    const onKeyDown = useCallback(
        event => {
            if (event.defaultPrevented) {
                return
            }

            blockOnMouseOver.current = true

            switch (event.key) {
                case "ArrowUp":
                    focusValue("previous")
                    break
                case "ArrowDown":
                    focusValue("next")
                    break
                case "Enter":
                    onChange(options[active].value)
                    setInFocus(false)
                    break
                case "Tab":
                    if (!inFocus) return
                    onChange(options[active].value)
                    setInFocus(false)
                    break
                case "Escape":
                    setInFocus(false)
                    break
                default:
                    return
            }
            event.preventDefault()
        },
        [active, options, onChange, focusValue, blockOnMouseOver, inFocus]
    )

    const onClick = (value, index) => {
        setInFocus(false)
        setActive(index)
        onChange(value)
        onChangeProp && onChangeProp(value)
    }

    const onMouseOver = index => {
        if (blockOnMouseOver.current) return

        setActive(index)
    }

    const onMouseMove = () => {
        blockOnMouseOver.current = false
    }

    useOnClickOutside(isInside => setInFocus(isInside), [optionsRef])

    return (
        <div
            style={{ width: width }}
            className={classNames(SelectStyles.block, {
                [SelectStyles.error]: error,
                [variant]: variant,
            })}
        >
            <WrapWithError error={error} id={name}>
                <div
                    className={classNames(SelectStyles.select, {
                        [SelectStyles.open]: inFocus,
                        [SelectStyles.empty]: !value,
                    })}
                >
                    {(options.find(o => o.value === value)?.ornament || options[active]?.ornament) && (
                        <div className={SelectStyles.ornament}>
                            {options.find(o => o.value === value)?.ornament || options[active]?.ornament}
                        </div>
                    )}
                    <input
                        type="text"
                        onFocus={() => setInFocus(true)}
                        onClick={() => setInFocus(true)}
                        onKeyDown={e => onKeyDown(e)}
                        onChange={e => onChange(e)}
                        placeholder={placeholder}
                        value={
                            active === undefined
                                ? undefined
                                : options.find(o => o.value === value)?.label || options[active]?.label
                        }
                        readOnly
                        id={name}
                        className="input"
                        style={{ height: height }}
                        ref={ref}
                        size={
                            autoSize && (options.find(o => o.value === value)?.label || options[active]?.label)?.length
                        }
                        required={required}
                        aria-invalid={!!error}
                        aria-errormessage={"err-" + name}
                        {...rest}
                    />
                    {label && (
                        <label className="label" htmlFor={name}>
                            {label} {required && <span style={{ color: "#d24d57" }}>*</span>}
                        </label>
                    )}
                    <i className="lavita-icon"></i>
                </div>
                <div className={SelectStyles.options}>
                    <div ref={optionsRef} style={{ maxHeight }}>
                        {options.map((option, index) => (
                            <button
                                type="button"
                                key={option.value}
                                onClick={() => onClick(option.value, index)}
                                onFocus={onMouseMove}
                                onMouseOver={() => onMouseOver(index)}
                                onMouseMove={onMouseMove}
                                className={classNames({ [SelectStyles.selectActive]: active === index })}
                                style={{ height: height }}
                            >
                                {option.ornament && <div className={SelectStyles.ornament}>{option.ornament}</div>}
                                {option.label}
                            </button>
                        ))}
                    </div>
                </div>
            </WrapWithError>
        </div>
    )
}

export default Select
