import { Spacer, SpacerVariant } from "../components/layout"

import React from "react"
import { types } from "react-bricks/frontend"

export interface DividerProps {
    spacerTop?: boolean
    spacerBottom?: boolean
    text?: string
}

const Divider: types.Brick<DividerProps> = ({ spacerTop, spacerBottom, text }) => {
    return (
        <>
            <Spacer variant={!spacerTop ? SpacerVariant.None : undefined} />
            {text ? (
                <div className="mb-3.5 flex w-full items-center justify-center gap-x-3 pt-1">
                    <div className="h-px w-full bg-[#eaeaea]" />
                    <p className="text-xs uppercase lg:text-base">{text}</p>
                    <div className="h-px w-full bg-[#eaeaea]" />
                </div>
            ) : (
                <div className="h-px w-full bg-border-tide sm:h-0.5" />
            )}
            <Spacer variant={!spacerBottom ? SpacerVariant.None : undefined} />
        </>
    )
}

Divider.schema = {
    name: "Divider",
    label: "Divider",
    sideEditProps: [
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): DividerProps => ({
        spacerTop: true,
        spacerBottom: true,
    }),
}

export default Divider
