import React from "react"
import { RenderElementProps } from "slate-react"

const SmallUl = ({ children, attributes }: RenderElementProps) => {
    return (
        <ul {...attributes} className="mb-3 list-none text-sm last:mb-0 lg:mb-5 lg:text-base">
            {children}
        </ul>
    )
}

export default SmallUl
