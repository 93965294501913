import { A, Link } from "../../utils/router"

import IconActionStyles from "./icon-action.module.css"
import React from "react"
import classNames from "classnames"

type IconActionProps = {
    button?: boolean
    white?: boolean
    red?: boolean
    center?: boolean
    big?: boolean
    bigIcon?: boolean
    underline?: boolean
    hideTextMobile?: boolean
    hideIconMobile?: boolean
    hideText?: boolean
    fullWidth?: boolean
    disabled?: boolean
    notAllowed?: boolean
    variant?: string
    to?: string
    state?: any
    id?: string
    withOnClick?: () => void
    url?: string
    action?: () => void
    submit?: boolean
    icon?: string
    title: string
    smallButton?: boolean
}

const IconAction = React.forwardRef<any, IconActionProps>(
    (
        {
            button,
            white,
            red,
            center,
            big,
            bigIcon,
            underline,
            hideTextMobile,
            hideIconMobile,
            hideText,
            fullWidth,
            disabled,
            notAllowed,
            variant,
            to,
            state,
            id,
            withOnClick,
            url,
            action,
            submit,
            icon,
            title,
            smallButton,
            ...rest
        },
        ref
    ) => {
        const classes = classNames(IconActionStyles.block, {
            [IconActionStyles.button]: button,
            [IconActionStyles.white]: white,
            [IconActionStyles.red]: red,
            [IconActionStyles.center]: center,
            [IconActionStyles.big]: big,
            [IconActionStyles.bigIcon]: bigIcon,
            [IconActionStyles.underline]: underline,
            [IconActionStyles.hideTextMobile]: hideTextMobile,
            [IconActionStyles.hideText]: hideText,
            [IconActionStyles.fullWidth]: fullWidth,
            [IconActionStyles.disabled]: disabled,
            [IconActionStyles.notAllowed]: notAllowed,
            [IconActionStyles.hideIconMobile]: hideIconMobile,
            [IconActionStyles.smallButton]: smallButton,
            // @ts-ignore
            [variant]: variant,
        })

        return to ? (
            <Link to={to} state={state} id={id} className={classes} onClick={withOnClick} ref={ref} {...rest}>
                {icon && <i className="lavita-icon">{icon}</i>}
                <span>{title}</span>
            </Link>
        ) : url ? (
            <A href={url} state={state} id={id} className={classes} onClick={withOnClick} ref={ref} {...rest}>
                {icon && <i className="lavita-icon">{icon}</i>}
                <span>{title}</span>
            </A>
        ) : action ? (
            <button type="button" id={id} className={classes} onClick={action} ref={ref} {...rest}>
                {icon && <i className="lavita-icon">{icon}</i>}
                <span>{title}</span>
            </button>
        ) : submit ? (
            <button type="submit" id={id} className={classes} ref={ref} {...rest}>
                {icon && <i className="lavita-icon">{icon}</i>}
                <span>{title}</span>
            </button>
        ) : disabled ? (
            <div className={classes} ref={ref} {...rest}>
                {icon && <i className="lavita-icon">{icon}</i>}
                <span>{title}</span>
            </div>
        ) : null
    }
)

export default IconAction
