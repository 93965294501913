import { MuteButton, VideoButton } from "./video-button"
import React, { ReactNode, useRef, useState } from "react"

import CloudflarePlayer from "./cloudflare-player"
import { Icons } from "../../../types"
import { StreamPlayerApi } from "@cloudflare/stream-react"
import classNames from "classnames"

interface VideoPlayerProps {
    children?: ReactNode
    cloudflareVideoId: string
    autoplay?: boolean
    isDecorativeVideo?: boolean
}

const VideoPlayer = ({ children, cloudflareVideoId, autoplay, isDecorativeVideo }: VideoPlayerProps) => {
    const player = useRef<StreamPlayerApi>()

    const [started, setStarted] = useState(!!autoplay)
    const [playing, setPlaying] = useState(!!autoplay)
    const [muted, setMuted] = useState(!!autoplay)
    const [progress, setProgress] = useState(0)

    const toggleVideo = () => {
        setPlaying(play => {
            if (!player.current) return !play

            if (play) {
                player.current.pause()
            } else {
                player.current.play().catch(() => {
                    // @ts-ignore
                    player.current.muted = true
                    setMuted(true)
                    // @ts-ignore
                    player.current.play()
                })
            }
            return !play
        })
    }

    return (
        <div className="relative">
            <div
                className={classNames("aspect-h-9 aspect-w-16 bg-black", { hidden: children && !started })}
                aria-hidden={isDecorativeVideo}
            >
                <CloudflarePlayer
                    id={cloudflareVideoId}
                    mute={muted}
                    autoplay={!!autoplay}
                    onProgress={progress => setProgress(progress)}
                    onEnded={() => {
                        setStarted(false)
                        setPlaying(false)
                    }}
                    loop={false}
                    streamRef={player}
                />
            </div>
            {started ? (
                <>
                    <button
                        type="button"
                        onClick={() => setMuted(muted => !muted)}
                        className="absolute bottom-[25px] right-[65px] md:right-[75px]"
                    >
                        <MuteButton muted={muted} />
                    </button>
                    <button type="button" onClick={toggleVideo} className="absolute bottom-[25px] right-[25px]">
                        <VideoButton playing={playing} progress={progress} />
                    </button>
                </>
            ) : (
                <>
                    <button
                        onClick={() => {
                            setStarted(true)
                            toggleVideo()
                        }}
                        className="absolute left-1/2 top-1/2 flex h-15 w-15 -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center rounded-base bg-white align-middle shadow-base sm:h-20 sm:w-20 lg:h-25 lg:w-25 lg:rounded-lg"
                    >
                        <i className="lavita-icon text-[25px] text-blue sm:text-[35px] lg:text-[45px]">{Icons.play}</i>
                    </button>
                    {children}
                </>
            )}
        </div>
    )
}

export default VideoPlayer
