import { Container, Spacer, SpacerVariant } from "../components/layout"
import { Repeater, types } from "react-bricks/frontend"

import React from "react"

export interface IconTwoColumnsTextGridProps {
    spacerTop: boolean
    spacerBottom: boolean
}

const IconTwoColumnsTextGrid: types.Brick<IconTwoColumnsTextGridProps> = ({ spacerTop, spacerBottom }) => {
    return (
        <Container>
            <Spacer variant={!spacerTop ? SpacerVariant.None : undefined} />
            <Repeater propName="centerText" />
            <ul>
                <Repeater propName="repeater" />
            </ul>
            <Spacer variant={!spacerBottom ? SpacerVariant.None : undefined} />
        </Container>
    )
}

IconTwoColumnsTextGrid.schema = {
    name: "IconTwoColumnsTextGrid",
    label: "Icon 2 Columns Text Grid",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "IconTwoColumnsText",
                    label: "Icon 2 Columns Text",
                    min: 1,
                    max: 10,
                },
            ],
        },
        {
            name: "centerText",
            items: [
                {
                    type: "CenterText",
                    label: "Text",
                    max: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): IconTwoColumnsTextGridProps => ({
        spacerTop: true,
        spacerBottom: true,
    }),
}

export default IconTwoColumnsTextGrid
