import i18next from "i18next"
import { initReactI18next } from "react-i18next"

let resources = {
    de: {
        translations: require("../locales/de/translations.json"),
        shared: require("../shared/locales/de/shared.json"),
    },
    en: {
        translations: require("../locales/en/translations.json"),
        shared: require("../shared/locales/en/shared.json"),
    },
    pl: {
        translations: require("../locales/pl/translations.json"),
        shared: require("../shared/locales/pl/shared.json"),
    },
    "de-CH": {
        translations: require("../locales/de-CH/translations.json"),
        shared: require("../shared/locales/de-CH/shared.json"),
    },
    "en-CH": {
        translations: require("../locales/en-CH/translations.json"),
        shared: require("../shared/locales/en-CH/shared.json"),
    },
    sv: {
        translations: require("../locales/sv/translations.json"),
        shared: require("../shared/locales/sv/shared.json"),
    },
    fr: {
        translations: require("../locales/fr/translations.json"),
        shared: require("../shared/locales/fr/shared.json"),
    },
    "fr-CH": {
        shared: require("../shared/locales/fr-CH/shared.json"),
    },
    it: {
        translations: require("../locales/it/translations.json"),
        shared: require("../shared/locales/it/shared.json"),
    },
    "it-CH": {
        shared: require("../shared/locales/it-CH/shared.json"),
    },
    es: {
        translations: require("../locales/es/translations.json"),
        shared: require("../shared/locales/es/shared.json"),
    },
    nl: {
        translations: require("../locales/nl/translations.json"),
        shared: require("../shared/locales/nl/shared.json"),
    },
    no: {
        translations: require("../locales/no/translations.json"),
        shared: require("../shared/locales/no/shared.json"),
    },
    da: {
        translations: require("../locales/da/translations.json"),
        shared: require("../shared/locales/da/shared.json"),
    },
    tr: {
        translations: require("../locales/tr/translations.json"),
        shared: require("../shared/locales/tr/shared.json"),
    },
    cs: {
        translations: require("../locales/cs/translations.json"),
        shared: require("../shared/locales/cs/shared.json"),
    },
}

i18next.use(initReactI18next).init({
    resources: resources,
    lng: process.env.DEFAULT_LANGUAGE,
    fallbackLng: ["en"],
    ns: ["translations"],
    defaultNS: "translations",
    returnObjects: true,
    debug: process.env.NODE_ENV === "development",
    interpolation: {
        escapeValue: false,
    },
    react: {
        useSuspense: true,
    },
})

export default i18next
