import { Background, BackgroundColor, Container, Spacer, SpacerVariant } from "../components/layout"
import { types, usePageValues } from "react-bricks/frontend"

import ColumnProduct from "./ColumnProduct"
import { EqualHeight } from "react-equal-height/clean"
import type { Product as ProductType } from "@lavita-io/international-sdk"
import React from "react"
import client from "../../shared/utils/client"
import { useClientState } from "../../shared/context/client"
import { useLocalesState } from "../../shared/context/locales"
import { usePageContext } from "../../context/page"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

export interface FixedThreeColumnProductsProps {
    spacerTop: boolean
    spacerBottom: boolean
    id?: string
}

const FixedThreeColumnProducts: types.Brick<FixedThreeColumnProductsProps> = ({ spacerTop, spacerBottom, id }) => {
    const { t } = useTranslation("translations")
    const { region } = useLocalesState()
    const { page: pageContext, products: productsBuildTime } = usePageContext()
    const { consumerToken } = useClientState()

    const [pageValues] = usePageValues()

    const page = pageContext || pageValues

    const { data } = useQuery<ProductType[]>(
        ["products", region, page?.language],
        () => client.getProducts({ country: region }, page?.language),
        {
            enabled: !!(page && consumerToken),
        }
    )

    const sortOrder = ["LV500", "LV2START", "LVSTARTNA", "SLP500", "LV75", "NALV75"]

    const filteredProducts = (data || productsBuildTime)
        ?.filter(p => sortOrder.includes(p.sku))
        .sort((a, b) => sortOrder.indexOf(a.sku) - sortOrder.indexOf(b.sku))

    const firstThreeProducts = filteredProducts?.slice(0, 3)

    const productDetails = {
        SLP500: {
            badge: t("slp500_badge_priceAdvantage"),
        },
    }

    return (
        <Background color={BackgroundColor.Grey}>
            <Container>
                <Spacer variant={!spacerTop ? SpacerVariant.None : undefined} />
                <EqualHeight>
                    <ul className="grid grid-cols-1 gap-x-3.5 gap-y-5 sm:grid-cols-3 md:gap-x-5 lg:gap-x-6" id={id}>
                        {firstThreeProducts?.map(product => (
                            <ColumnProduct
                                products={filteredProducts}
                                sku={product.sku}
                                key={product.sku}
                                // @ts-ignore
                                {...productDetails[product.sku]}
                            />
                        ))}
                    </ul>
                </EqualHeight>
                <Spacer variant={!spacerBottom ? SpacerVariant.None : undefined} />
            </Container>
        </Background>
    )
}

FixedThreeColumnProducts.schema = {
    name: "FixedThreeColumnProducts",
    label: "Fixed 3 Column Products",
    sideEditProps: [
        {
            name: "id",
            label: "ID",
            type: types.SideEditPropType.Text,
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): FixedThreeColumnProductsProps => ({
        spacerTop: true,
        spacerBottom: true,
    }),
}

export default FixedThreeColumnProducts
