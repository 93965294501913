import React from "react"
import { RenderElementProps } from "slate-react"

const SmallCheckList = ({ children, attributes }: RenderElementProps) => {
    return (
        <li
            {...attributes}
            data-before=""
            className="relative mb-3 pl-8 before:absolute before:left-0 before:top-0 before:z-2 before:font-['lavita-icon-font'] before:text-sm before:content-[attr(data-before)] last:mb-0 lg:mb-5 before:lg:text-md"
        >
            {children}
        </li>
    )
}

export default SmallCheckList
