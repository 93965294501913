import React from "react"
import WrapWithErrorStyles from "./wrap-with-error.module.css"
import classNames from "classnames"

const WrapWithError = props => {
    return (
        <>
            {props.children}
            <div
                className={classNames(WrapWithErrorStyles.block, {
                    [WrapWithErrorStyles.error]: props.error,
                    [WrapWithErrorStyles.info]: props.info,
                    [WrapWithErrorStyles.noSpace]: props.noSpace,
                    [WrapWithErrorStyles.optional]: props.optional,
                })}
            >
                <i className="lavita-icon"></i>
                <span aria-live="polite" id={"err-" + props.id}>{props.error?.message || props.info?.message}</span>
            </div>
        </>
    )
}

export default WrapWithError
