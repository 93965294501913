import { Background, BackgroundColor, Container, Spacer, SpacerVariant } from "../components/layout"
import React, { useEffect, useMemo, useState } from "react"
import { Repeater, types, useAdminContext, usePageValues } from "react-bricks/frontend"

import { EqualHeight } from "react-equal-height/clean"
import { MatrixItemProps } from "./matrix/Item"
import { Product } from "@lavita-io/international-sdk"
import classNames from "classnames"
import client from "../../shared/utils/client"
import { useClientState } from "../../shared/context/client"
import { useLocalesState } from "../../shared/context/locales"
import { usePageContext } from "../../context/page"
import { useQuery } from "@tanstack/react-query"

export interface ProductMatrixProps {
    repeater?: {
        id: string
        props: MatrixItemProps
        type: "MatrixItem"
    }[]
    sku?: string
    spacerTop: boolean
    spacerBottom: boolean
    id?: string
    index?: number
}

const ProductMatrix: types.Brick<ProductMatrixProps> = ({ repeater, spacerTop, spacerBottom, id, sku, index }) => {
    const { region } = useLocalesState()
    const { products: productsBuildTime, page: pageContext } = usePageContext()
    const { consumerToken } = useClientState()

    const [pageValues] = usePageValues()

    const page = pageContext || pageValues

    const { data } = useQuery<Product[]>(
        ["products", ...(repeater?.map(r => r.props.sku).filter(r => r) || []), region, page?.language],
        () =>
            client.getProducts(
                { sku: repeater?.map(r => r.props.sku).filter(r => r), country: region },
                page?.language
            ),
        {
            enabled: !!(repeater?.length && page && consumerToken),
        }
    )

    const products = data || productsBuildTime

    const [activeTab, setActiveTab] = useState(repeater?.findIndex(r => r.props.sku === sku) || 0)

    const changeTab = (sku?: string) => {
        if (!repeater || !sku) return
        setActiveTab(repeater.findIndex(r => r.props.sku === sku))
    }

    const { isAdmin } = useAdminContext()

    useEffect(() => {
        if (!products || !repeater) return

        setActiveTab(
            repeater.findIndex(
                r =>
                    !r.props.sku || (products.map(p => p.sku).includes(r.props.sku) && sku ? r.props.sku === sku : true)
            ) || 0
        )
    }, [products, repeater, sku])

    const repeaterLengthFiltered = useMemo(
        () =>
            repeater?.filter(r => !(r.props.sku && !products?.find(p => p.sku === r.props.sku) && !isAdmin)).length ||
            0,
        [repeater, products, isAdmin]
    )

    return (
        <div key={index}>
            <Background color={BackgroundColor.White} className="overflow-hidden">
                <Container>
                    <div id={id}>
                        <Spacer variant={!spacerTop ? SpacerVariant.None : SpacerVariant.Small} />
                        <Repeater propName="centerText" />
                        <EqualHeight updateOnChange={[repeaterLengthFiltered, activeTab]}>
                            <ul
                                className={classNames(
                                    "grid gap-1.5 pt-5 sm:gap-3.5 md:gap-5 lg:gap-6",
                                    repeaterLengthFiltered % 3 === 0
                                        ? "grid-cols-3 sm:grid-cols-6"
                                        : "grid-cols-[repeat(2,1fr)] sm:grid-cols-4 lg:grid-cols-6"
                                )}
                            >
                                <Repeater
                                    propName="repeater"
                                    itemProps={
                                        {
                                            products,
                                            repeaterLength: repeater?.length || 0,
                                            repeaterLengthFiltered,
                                            changeTab,
                                            activeTab,
                                            firstOnMobile: sku,
                                        } as MatrixItemProps
                                    }
                                />
                            </ul>
                            <ul className="sm:hidden">
                                <Repeater
                                    propName="repeater"
                                    itemProps={
                                        {
                                            products,
                                            repeaterLengthFiltered,
                                            changeTab,
                                            activeTab,
                                            showActiveOnly: true,
                                        } as MatrixItemProps
                                    }
                                />
                            </ul>
                        </EqualHeight>
                    </div>
                    <Spacer variant={!spacerBottom ? SpacerVariant.None : SpacerVariant.Small} />
                </Container>
            </Background>
        </div>
    )
}

ProductMatrix.schema = {
    name: "ProductMatrix",
    label: "Product Matrix",
    repeaterItems: [
        {
            name: "centerText",
            items: [
                {
                    type: "Trust",
                    label: "Trust Badge",
                    min: 0,
                    max: 1,
                },
                {
                    type: "CenterText",
                    label: "Text",
                    max: 1,
                },
            ],
        },
        {
            name: "repeater",
            items: [
                {
                    type: "MatrixItem",
                    label: "Product",
                    min: 2,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "sku",
            label: "first Product Mobile",
            type: types.SideEditPropType.Text,
        },
        {
            name: "id",
            label: "ID",
            type: types.SideEditPropType.Text,
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): ProductMatrixProps => ({
        spacerTop: true,
        spacerBottom: true,
    }),
}

export default ProductMatrix
