import { usePostContact, useQueryContactCategories } from "../../hooks/contact/use-query-contact"

import ContactStyles from "./contact.module.css"
import FlexWrapper from "../../shared/components/fields/flex-wrapper"
import IconAction from "../../shared/components/universal/icon-action"
import Input from "../../shared/components/fields/input"
import { Link } from "../../shared/utils/router"
import React from "react"
import Select from "../../shared/components/fields/select"
import Textarea from "../../shared/components/fields/textarea"
import { toast } from "react-toastify"
import { useForm } from "react-hook-form"
import { useLocalesState } from "../../shared/context/locales"
import { useTranslation } from "react-i18next"

const Contact = () => {
    const { t } = useTranslation("translations")
    const { region } = useLocalesState()

    const { handleSubmit, control, reset } = useForm({
        mode: "onTouched",
        defaultValues: {
            firstname: "",
            lastname: "",
            email: "",
            tel: "",
            category: "",
            textarea: "",
        },
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
    })

    const { data } = useQueryContactCategories()
    const mutate = usePostContact()

    const submit = async values => {
        const category = region !== "TR" ? values.category : process.env.CONTACT_TR_CATEGORY_ID

        const body = {
            category_id: category,
            firstname: values.firstname,
            lastname: values.lastname,
            email: values.email,
            phone: values.phone,
            body: values.textarea,
        }

        try {
            await mutate.mutateAsync(body, { throwError: true })
            toast.success(t("contact_form_success"), { autoClose: 5000 })
            reset()
        } catch (error) {
            return
        }
    }

    return (
        <div className={ContactStyles.block}>
            <form onSubmit={handleSubmit(submit)} style={{ maxWidth: "100%" }} name="contact">
                <FlexWrapper flex={["2 1 auto", "2 1 auto"]} wrap>
                    <Input
                        control={control}
                        rules={{ required: { value: true, message: t("contact_form_firstname_required") } }}
                        type="text"
                        name="firstname"
                        label={t("contact_form_firstname_label")}
                        autocomplete="given-name"
                        required
                    />
                    <Input
                        control={control}
                        rules={{ required: { value: true, message: t("contact_form_lastname_required") } }}
                        type="text"
                        name="lastname"
                        label={t("contact_form_lastname_label")}
                        autocomplete="family-name"
                        required
                    />
                </FlexWrapper>
                <FlexWrapper flex={["2 1 auto", "2 1 auto"]} wrap>
                    <Input
                        control={control}
                        rules={{ required: { value: true, message: t("contact_form_email_required") } }}
                        type="email"
                        name="email"
                        label={t("contact_form_email_label")}
                        autocomplete="email"
                        required
                    />
                    <Input
                        control={control}
                        rules={{ required: { value: false } }}
                        type="tel"
                        name="tel"
                        label={t("contact_form_phone_label")}
                        autocomplete="tel"
                    />
                </FlexWrapper>
                {region !== "TR" && (
                    <Select
                        control={control}
                        rules={{ required: { value: true, message: t("contact_form_category_required") } }}
                        name="category"
                        options={
                            data?.categories
                                .filter(category => category.id !== process.env.CONTACT_TR_CATEGORY_ID)
                                .map(category => ({
                                    label: category.name,
                                    value: category.id,
                                })) || []
                        }
                        placeholder={t("contact_form_category_placeholder")}
                        autocomplete="category"
                        required
                    />
                )}
                <Textarea
                    control={control}
                    rules={{ required: { value: true, message: t("contact_form_textarea_required") } }}
                    type="textarea"
                    name="textarea"
                    autocomplete="textarea"
                    placeholder={t("contact_form_textarea_placeholder")}
                    required
                />
                <p className={ContactStyles.legal}>
                    {t("contact_form_legal")}
                    <Link to={`/${t("contact_form_legal_slug")}`}>{t("contact_form_legal_link")}</Link>
                </p>
                <p className={ContactStyles.required}>
                    <span style={{ color: "#c53929" }}>* </span>
                    {t("startpaket_form_required_filed")}
                </p>
                <IconAction submit button title={t("contact_form_submit")} />
            </form>
        </div>
    )
}

export default Contact
