const micronutrients = {
    de: {
        head: [
            {
                id: "0",
                text: "Mikronähr­stoffe",
            },
            {
                id: "1",
                text: "100 ml LaVita-Fertig­getränk",
                asterisk: "1",
            },
            {
                id: "2",
                text: "% pro 100 ml Fertig­getränk",
                asterisk: "2",
            },
            {
                id: "3",
                text: "2x10 ml LaVita",
                asterisk: "3",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "Vitamin C",
                },
                {
                    id: "1",
                    text: "300 mg",
                },
                {
                    id: "2",
                    text: "375",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin E",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "250",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin A (als Beta-Carotin)",
                },
                {
                    id: "1",
                    text: "417 µg RE*",
                },
                {
                    id: "2",
                    text: "52",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin B1",
                },
                {
                    id: "1",
                    text: "3 mg",
                },
                {
                    id: "2",
                    text: "273",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin B2",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Niacin",
                },
                {
                    id: "1",
                    text: "15 mg",
                },
                {
                    id: "2",
                    text: "94",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Pantothen­säure",
                },
                {
                    id: "1",
                    text: "8 mg",
                },
                {
                    id: "2",
                    text: "133",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin B6",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Folsäure",
                },
                {
                    id: "1",
                    text: "200 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin B12",
                },
                {
                    id: "1",
                    text: "5 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Biotin",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin K",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "40",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin D",
                },
                {
                    id: "1",
                    text: "400 I.E. / 10 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Magnesium",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "8",
                },
                {
                    id: "3",
                    text: "60%",
                },
            ],
            [
                {
                    id: "0",
                    text: "Selen",
                },
                {
                    id: "1",
                    text: "35 µg",
                },
                {
                    id: "2",
                    text: "64",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Kupfer",
                },
                {
                    id: "1",
                    text: "0,25 mg",
                },
                {
                    id: "2",
                    text: "25",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Eisen",
                },
                {
                    id: "1",
                    text: "4 mg",
                },
                {
                    id: "2",
                    text: "29",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Zink",
                },
                {
                    id: "1",
                    text: "5 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Mangan",
                },
                {
                    id: "1",
                    text: "1 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Chrom",
                },
                {
                    id: "1",
                    text: "20 µg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Jod",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "33",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Molybdän",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "60",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
        ],
    },
    "de-CH": {
        head: [
            {
                id: "0",
                text: "Mikronähr­stoffe",
            },
            {
                id: "1",
                text: "100 ml LaVita-Fertiggetränk",
                asterisk: "1",
            },
            {
                id: "2",
                text: "% pro 100 ml Fertiggetränk",
                asterisk: "2",
            },
            {
                id: "3",
                text: "2x10 ml LaVita",
                asterisk: "3",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "Vitamin C",
                },
                {
                    id: "1",
                    text: "300 mg",
                },
                {
                    id: "2",
                    text: "375",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin E",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "250",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin A (als Beta-Carotin)",
                },
                {
                    id: "1",
                    text: "417 µg RE*",
                },
                {
                    id: "2",
                    text: "52",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin B1",
                },
                {
                    id: "1",
                    text: "3 mg",
                },
                {
                    id: "2",
                    text: "273",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin B2",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Niacin",
                },
                {
                    id: "1",
                    text: "15 mg",
                },
                {
                    id: "2",
                    text: "94",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Pantothen­säure",
                },
                {
                    id: "1",
                    text: "8 mg",
                },
                {
                    id: "2",
                    text: "133",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin B6",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Folsäure",
                },
                {
                    id: "1",
                    text: "200 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin B12",
                },
                {
                    id: "1",
                    text: "5 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Biotin",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin K",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "40",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin D",
                },
                {
                    id: "1",
                    text: "400 I.E. / 10 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Magnesium",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "8",
                },
                {
                    id: "3",
                    text: "60%",
                },
            ],
            [
                {
                    id: "0",
                    text: "Selen",
                },
                {
                    id: "1",
                    text: "35 µg",
                },
                {
                    id: "2",
                    text: "64",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Kupfer",
                },
                {
                    id: "1",
                    text: "0,25 mg",
                },
                {
                    id: "2",
                    text: "25",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Eisen",
                },
                {
                    id: "1",
                    text: "4 mg",
                },
                {
                    id: "2",
                    text: "29",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Zink",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "25",
                },
                {
                    id: "3",
                    text: "50%",
                },
            ],
            [
                {
                    id: "0",
                    text: "Mangan",
                },
                {
                    id: "1",
                    text: "1 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Chrom",
                },
                {
                    id: "1",
                    text: "20 µg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Jod",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "33",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Molybdän",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "60",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
        ],
    },
    en: {
        head: [
            {
                id: "0",
                text: "Micronutrients",
            },
            {
                id: "1",
                text: "100 ml LaVita ready drink",
                asterisk: "1",
            },
            {
                id: "2",
                text: "% per 100 ml ready drink",
                asterisk: "2",
            },
            {
                id: "3",
                text: "2x10 ml LaVita",
                asterisk: "3",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "Vitamin C",
                },
                {
                    id: "1",
                    text: "300 mg",
                },
                {
                    id: "2",
                    text: "375",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin E",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "250",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin A (as beta carotene)",
                },
                {
                    id: "1",
                    text: "417 µg RE*",
                },
                {
                    id: "2",
                    text: "52",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin B1",
                },
                {
                    id: "1",
                    text: "3 mg",
                },
                {
                    id: "2",
                    text: "273",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin B2",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Niacin",
                },
                {
                    id: "1",
                    text: "15 mg",
                },
                {
                    id: "2",
                    text: "94",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Pantothenic acid",
                },
                {
                    id: "1",
                    text: "8 mg",
                },
                {
                    id: "2",
                    text: "133",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin B6",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Folic acid",
                },
                {
                    id: "1",
                    text: "200 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin B12",
                },
                {
                    id: "1",
                    text: "5 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Biotin",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin K",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "40",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin D",
                },
                {
                    id: "1",
                    text: "400 I.E. / 10 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Magnesium",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "8",
                },
                {
                    id: "3",
                    text: "60%",
                },
            ],
            [
                {
                    id: "0",
                    text: "Selenium",
                },
                {
                    id: "1",
                    text: "35 µg",
                },
                {
                    id: "2",
                    text: "64",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Copper",
                },
                {
                    id: "1",
                    text: "0,25 mg",
                },
                {
                    id: "2",
                    text: "25",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Iron",
                },
                {
                    id: "1",
                    text: "4 mg",
                },
                {
                    id: "2",
                    text: "29",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Zinc",
                },
                {
                    id: "1",
                    text: "5 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Manganese",
                },
                {
                    id: "1",
                    text: "1 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Chromium",
                },
                {
                    id: "1",
                    text: "20 µg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Iodine",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "33",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Molybdenum",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "60",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
        ],
    },
    pl: {
        head: [
            {
                id: "0",
                text: "Mikroskładniki odżywcze",
            },
            {
                id: "1",
                text: "100 ml gotowego napoju LaVita",
                asterisk: "1",
            },
            {
                id: "2",
                text: "% na 100 ml gotowego napoju",
                asterisk: "2",
            },
            {
                id: "3",
                text: "2x10 ml LaVita",
                asterisk: "3",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "Witamina C",
                },
                {
                    id: "1",
                    text: "300 mg",
                },
                {
                    id: "2",
                    text: "375",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Witamina E",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "250",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Witamina A (jako beta- karoten)",
                },
                {
                    id: "1",
                    text: "417 µg RE*",
                },
                {
                    id: "2",
                    text: "52",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Witamina B1",
                },
                {
                    id: "1",
                    text: "3 mg",
                },
                {
                    id: "2",
                    text: "273",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Witamina B2",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Niacyna",
                },
                {
                    id: "1",
                    text: "15 mg",
                },
                {
                    id: "2",
                    text: "94",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Kwas pantotenowy",
                },
                {
                    id: "1",
                    text: "8 mg",
                },
                {
                    id: "2",
                    text: "133",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Witamina B6",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Kwas foliowy",
                },
                {
                    id: "1",
                    text: "200 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Witamina B12",
                },
                {
                    id: "1",
                    text: "5 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Biotyna",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Witamina K",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "40",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Witamina D",
                },
                {
                    id: "1",
                    text: "400 I.E. / 10 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Magnez",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "8",
                },
                {
                    id: "3",
                    text: "60%",
                },
            ],
            [
                {
                    id: "0",
                    text: "Selen",
                },
                {
                    id: "1",
                    text: "35 µg",
                },
                {
                    id: "2",
                    text: "64",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Miedź",
                },
                {
                    id: "1",
                    text: "0,25 mg",
                },
                {
                    id: "2",
                    text: "25",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Żelazo",
                },
                {
                    id: "1",
                    text: "4 mg",
                },
                {
                    id: "2",
                    text: "29",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Cynk",
                },
                {
                    id: "1",
                    text: "5 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Mangan",
                },
                {
                    id: "1",
                    text: "1 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Chrom",
                },
                {
                    id: "1",
                    text: "20 µg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Jod",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "33",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Molibden",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "60",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
        ],
    },
    da: {
        head: [
            {
                id: "0",
                text: "Mikronæringsstoffer",
            },
            {
                id: "1",
                text: "100 ml LaVita ready drink",
                asterisk: "1",
            },
            {
                id: "2",
                text: "% per 100 ml ready drink",
                asterisk: "2",
            },
            {
                id: "3",
                text: "2x10 ml LaVita",
                asterisk: "3",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "C-vitamin",
                },
                {
                    id: "1",
                    text: "300 mg",
                },
                {
                    id: "2",
                    text: "375",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "E-vitamin",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "250",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "A-vitamin (som betacaroten)",
                },
                {
                    id: "1",
                    text: "417 µg RE*",
                },
                {
                    id: "2",
                    text: "52",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "B1-vitamin",
                },
                {
                    id: "1",
                    text: "3 mg",
                },
                {
                    id: "2",
                    text: "273",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "B2-vitamin",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Niacin",
                },
                {
                    id: "1",
                    text: "15 mg",
                },
                {
                    id: "2",
                    text: "94",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Pantotensyre",
                },
                {
                    id: "1",
                    text: "8 mg",
                },
                {
                    id: "2",
                    text: "133",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "B6-vitamin",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Folsyre",
                },
                {
                    id: "1",
                    text: "200 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "B12-vitamin",
                },
                {
                    id: "1",
                    text: "5 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Biotin",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "K-vitamin**",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "40",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "D-vitamin",
                },
                {
                    id: "1",
                    text: "400 I.E. / 10 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Magnesium",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "8",
                },
                {
                    id: "3",
                    text: "60%",
                },
            ],
            [
                {
                    id: "0",
                    text: "Selen",
                },
                {
                    id: "1",
                    text: "35 µg",
                },
                {
                    id: "2",
                    text: "64",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Kobber",
                },
                {
                    id: "1",
                    text: "0,25 mg",
                },
                {
                    id: "2",
                    text: "25",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Jern",
                },
                {
                    id: "1",
                    text: "4 mg",
                },
                {
                    id: "2",
                    text: "29",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Zink",
                },
                {
                    id: "1",
                    text: "5 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Mangan",
                },
                {
                    id: "1",
                    text: "1 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Krom",
                },
                {
                    id: "1",
                    text: "20 µg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Jod",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "33",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Molybdæn",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "60",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
        ],
    },
    nl: {
        head: [
            {
                id: "0",
                text: "Micronutriënten",
            },
            {
                id: "1",
                text: "100 ml LaVita drankje bereid",
                asterisk: "1",
            },
            {
                id: "2",
                text: "% per 100 ml drankje bereid",
                asterisk: "2",
            },
            {
                id: "3",
                text: "2x10 ml LaVita",
                asterisk: "3",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "Vitamine C",
                },
                {
                    id: "1",
                    text: "300 mg",
                },
                {
                    id: "2",
                    text: "375",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamine E",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "250",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamine A (als bètacaroteen)",
                },
                {
                    id: "1",
                    text: "417 µg RE*",
                },
                {
                    id: "2",
                    text: "52",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamine B1",
                },
                {
                    id: "1",
                    text: "3 mg",
                },
                {
                    id: "2",
                    text: "273",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamine B2",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Niacine",
                },
                {
                    id: "1",
                    text: "15 mg",
                },
                {
                    id: "2",
                    text: "94",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Pantotheenzuur",
                },
                {
                    id: "1",
                    text: "8 mg",
                },
                {
                    id: "2",
                    text: "133",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamine B6",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Foliumzuur",
                },
                {
                    id: "1",
                    text: "200 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamine B12",
                },
                {
                    id: "1",
                    text: "5 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Biotine",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamine K",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "40",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamine D",
                },
                {
                    id: "1",
                    text: "400 I.E. / 10 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Magnesium",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "8",
                },
                {
                    id: "3",
                    text: "60%",
                },
            ],
            [
                {
                    id: "0",
                    text: "Seleen",
                },
                {
                    id: "1",
                    text: "35 µg",
                },
                {
                    id: "2",
                    text: "64",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Koper",
                },
                {
                    id: "1",
                    text: "0,25 mg",
                },
                {
                    id: "2",
                    text: "25",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "IJzer",
                },
                {
                    id: "1",
                    text: "4 mg",
                },
                {
                    id: "2",
                    text: "29",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Zink",
                },
                {
                    id: "1",
                    text: "5 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Mangaan",
                },
                {
                    id: "1",
                    text: "1 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Chroom",
                },
                {
                    id: "1",
                    text: "20 µg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Jood",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "33",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Molybdeen",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "60",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
        ],
    },
    es: {
        head: [
            {
                id: "0",
                text: "Micronutrientes",
            },
            {
                id: "1",
                text: "100 ml de bebida preparada LaVita",
                asterisk: "1",
            },
            {
                id: "2",
                text: "% por 100 ml de bebida preparada LaVita",
                asterisk: "2",
            },
            {
                id: "3",
                text: "2x10 ml LaVita",
                asterisk: "3",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "Vitamina C",
                },
                {
                    id: "1",
                    text: "300 mg",
                },
                {
                    id: "2",
                    text: "375",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamina E",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "250",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamina A (como beta-caroteno)",
                },
                {
                    id: "1",
                    text: "417 µg RE*",
                },
                {
                    id: "2",
                    text: "52",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamina B1",
                },
                {
                    id: "1",
                    text: "3 mg",
                },
                {
                    id: "2",
                    text: "273",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamina B2",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Niacina",
                },
                {
                    id: "1",
                    text: "15 mg",
                },
                {
                    id: "2",
                    text: "94",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Ácido pantoténico",
                },
                {
                    id: "1",
                    text: "8 mg",
                },
                {
                    id: "2",
                    text: "133",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamina B6",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Ácido fólico",
                },
                {
                    id: "1",
                    text: "200 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamina B12",
                },
                {
                    id: "1",
                    text: "5 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Biotina",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamina K",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "40",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamina D",
                },
                {
                    id: "1",
                    text: "400 I.E. / 10 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Magnesio",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "8",
                },
                {
                    id: "3",
                    text: "60%",
                },
            ],
            [
                {
                    id: "0",
                    text: "Selenio",
                },
                {
                    id: "1",
                    text: "35 µg",
                },
                {
                    id: "2",
                    text: "64",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Cobre",
                },
                {
                    id: "1",
                    text: "0,25 mg",
                },
                {
                    id: "2",
                    text: "25",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Hierro",
                },
                {
                    id: "1",
                    text: "4 mg",
                },
                {
                    id: "2",
                    text: "29",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Zinc",
                },
                {
                    id: "1",
                    text: "5 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Manganeso",
                },
                {
                    id: "1",
                    text: "1 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Cromo",
                },
                {
                    id: "1",
                    text: "20 µg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Yodo",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "33",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Molibdeno",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "60",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
        ],
    },
    cs: {
        head: [
            {
                id: "0",
                text: "mikronutrienty",
            },
            {
                id: "1",
                text: "100 ml hotového nápoje LaVita",
                asterisk: "1",
            },
            {
                id: "2",
                text: "% ve 100 ml hotového nápoje",
                asterisk: "2",
            },
            {
                id: "3",
                text: "2x10 ml LaVita",
                asterisk: "3",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "vitamín C",
                },
                {
                    id: "1",
                    text: "300 mg",
                },
                {
                    id: "2",
                    text: "375",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "vitamín E",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "250",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "vitamín A (jako betakaroten)",
                },
                {
                    id: "1",
                    text: "417 µg RE*",
                },
                {
                    id: "2",
                    text: "52",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "vitamín B1",
                },
                {
                    id: "1",
                    text: "3 mg",
                },
                {
                    id: "2",
                    text: "273",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "vitamín B2",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "niacin (vitamín B3)",
                },
                {
                    id: "1",
                    text: "15 mg",
                },
                {
                    id: "2",
                    text: "94",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "kyselina pantothenová",
                },
                {
                    id: "1",
                    text: "8 mg",
                },
                {
                    id: "2",
                    text: "133",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "vitamín B6",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "kyselina listová",
                },
                {
                    id: "1",
                    text: "200 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "vitamín B12",
                },
                {
                    id: "1",
                    text: "5 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "biotin (vitamín H)",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "vitamín K",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "40",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "vitamín D",
                },
                {
                    id: "1",
                    text: "400 I.E. / 10 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "hořčík",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "8",
                },
                {
                    id: "3",
                    text: "60%",
                },
            ],
            [
                {
                    id: "0",
                    text: "selen",
                },
                {
                    id: "1",
                    text: "35 µg",
                },
                {
                    id: "2",
                    text: "64",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "měď",
                },
                {
                    id: "1",
                    text: "0,25 mg",
                },
                {
                    id: "2",
                    text: "25",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "železo",
                },
                {
                    id: "1",
                    text: "4 mg",
                },
                {
                    id: "2",
                    text: "29",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "zinek",
                },
                {
                    id: "1",
                    text: "5 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "mangan",
                },
                {
                    id: "1",
                    text: "1 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "chrom",
                },
                {
                    id: "1",
                    text: "20 µg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "jód",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "33",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "molybden",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "60",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
        ],
    },
    no: {
        head: [
            {
                id: "0",
                text: "Mikronæringsstoffer",
            },
            {
                id: "1",
                text: "100 ml LaVita klar til å drikke",
                asterisk: "1",
            },
            {
                id: "2",
                text: "% per 100 ml klar til å drikke",
                asterisk: "2",
            },
            {
                id: "3",
                text: "2x10 ml LaVita",
                asterisk: "3",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "C-vitamin",
                },
                {
                    id: "1",
                    text: "300 mg",
                },
                {
                    id: "2",
                    text: "375",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "E-vitamin",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "250",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "A-vitamin (som betakaroten)",
                },
                {
                    id: "1",
                    text: "417 µg RE*",
                },
                {
                    id: "2",
                    text: "52",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "B1-vitamin ",
                },
                {
                    id: "1",
                    text: "3 mg",
                },
                {
                    id: "2",
                    text: "273",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "B2-vitamin",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Niacin",
                },
                {
                    id: "1",
                    text: "15 mg",
                },
                {
                    id: "2",
                    text: "94",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Pantotensyre",
                },
                {
                    id: "1",
                    text: "8 mg",
                },
                {
                    id: "2",
                    text: "133",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "B6-vitamin",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Folsyre",
                },
                {
                    id: "1",
                    text: "200 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "B12-vitamin",
                },
                {
                    id: "1",
                    text: "5 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Biotin",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "K-vitamin**",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "40",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "D-vitamin",
                },
                {
                    id: "1",
                    text: "400 I.E. / 10 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Magnesium",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "8",
                },
                {
                    id: "3",
                    text: "60%",
                },
            ],
            [
                {
                    id: "0",
                    text: "Selen",
                },
                {
                    id: "1",
                    text: "35 µg",
                },
                {
                    id: "2",
                    text: "64",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Kobber",
                },
                {
                    id: "1",
                    text: "0,25 mg",
                },
                {
                    id: "2",
                    text: "25",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Jern",
                },
                {
                    id: "1",
                    text: "4 mg",
                },
                {
                    id: "2",
                    text: "29",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Sink",
                },
                {
                    id: "1",
                    text: "5 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Mangan",
                },
                {
                    id: "1",
                    text: "1 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Krom",
                },
                {
                    id: "1",
                    text: "20 µg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Jod",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "33",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Molybden",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "60",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
        ],
    },
    it: {
        head: [
            {
                id: "0",
                text: "Micronutrienti",
            },
            {
                id: "1",
                text: "100 ml LaVita pronto da bere",
                asterisk: "1",
            },
            {
                id: "2",
                text: "% per 100 ml di bevanda preparata",
                asterisk: "2",
            },
            {
                id: "3",
                text: "2x10 ml LaVita",
                asterisk: "3",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "Vitamina C",
                },
                {
                    id: "1",
                    text: "300 mg",
                },
                {
                    id: "2",
                    text: "375",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamina E",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "250",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamina A (come beta carotene)",
                },
                {
                    id: "1",
                    text: "417 µg RE*",
                },
                {
                    id: "2",
                    text: "52",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamina B1",
                },
                {
                    id: "1",
                    text: "3 mg",
                },
                {
                    id: "2",
                    text: "273",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamina B2",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Niacina",
                },
                {
                    id: "1",
                    text: "15 mg",
                },
                {
                    id: "2",
                    text: "94",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Acido pantotenico",
                },
                {
                    id: "1",
                    text: "8 mg",
                },
                {
                    id: "2",
                    text: "133",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamina B6",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Acido folico",
                },
                {
                    id: "1",
                    text: "200 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamina B12",
                },
                {
                    id: "1",
                    text: "5 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Biotina",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamina K",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "40",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamina D",
                },
                {
                    id: "1",
                    text: "400 I.E. / 10 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Magnesio",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "8",
                },
                {
                    id: "3",
                    text: "60%",
                },
            ],
            [
                {
                    id: "0",
                    text: "Selenio",
                },
                {
                    id: "1",
                    text: "35 µg",
                },
                {
                    id: "2",
                    text: "64",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Rame",
                },
                {
                    id: "1",
                    text: "0,25 mg",
                },
                {
                    id: "2",
                    text: "25",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Ferro",
                },
                {
                    id: "1",
                    text: "4 mg",
                },
                {
                    id: "2",
                    text: "29",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Zinco",
                },
                {
                    id: "1",
                    text: "5 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Manganese",
                },
                {
                    id: "1",
                    text: "1 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Cromo",
                },
                {
                    id: "1",
                    text: "20 µg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Iodio",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "33",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Molibdeno",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "60",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
        ],
    },
    "it-CH": {
        head: [
            {
                id: "0",
                text: "Micronutrienti",
            },
            {
                id: "1",
                text: "100 ml LaVita pronto da bere",
                asterisk: "1",
            },
            {
                id: "2",
                text: "% per 100 ml di bevanda preparata",
                asterisk: "2",
            },
            {
                id: "3",
                text: "2x10 ml LaVita",
                asterisk: "3",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "Vitamina C",
                },
                {
                    id: "1",
                    text: "300 mg",
                },
                {
                    id: "2",
                    text: "375",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamina E",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "250",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamina A (come beta carotene)",
                },
                {
                    id: "1",
                    text: "417 µg RE*",
                },
                {
                    id: "2",
                    text: "52",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamina B1",
                },
                {
                    id: "1",
                    text: "3 mg",
                },
                {
                    id: "2",
                    text: "273",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamina B2",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Niacina",
                },
                {
                    id: "1",
                    text: "15 mg",
                },
                {
                    id: "2",
                    text: "94",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Acido pantotenico",
                },
                {
                    id: "1",
                    text: "8 mg",
                },
                {
                    id: "2",
                    text: "133",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamina B6",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Acido folico",
                },
                {
                    id: "1",
                    text: "200 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamina B12",
                },
                {
                    id: "1",
                    text: "5 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Biotina",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamina K",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "40",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamina D",
                },
                {
                    id: "1",
                    text: "400 I.E. / 10 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Magnesio",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "8",
                },
                {
                    id: "3",
                    text: "60%",
                },
            ],
            [
                {
                    id: "0",
                    text: "Selenio",
                },
                {
                    id: "1",
                    text: "35 µg",
                },
                {
                    id: "2",
                    text: "64",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Rame",
                },
                {
                    id: "1",
                    text: "0,25 mg",
                },
                {
                    id: "2",
                    text: "25",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Ferro",
                },
                {
                    id: "1",
                    text: "4 mg",
                },
                {
                    id: "2",
                    text: "29",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Zinco",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "25",
                },
                {
                    id: "3",
                    text: "50%",
                },
            ],
            [
                {
                    id: "0",
                    text: "Manganese",
                },
                {
                    id: "1",
                    text: "1 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Cromo",
                },
                {
                    id: "1",
                    text: "20 µg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Iodio",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "33",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Molibdeno",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "60",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
        ],
    },
    sv: {
        head: [
            {
                id: "0",
                text: "Mikronäringsämnen",
            },
            {
                id: "1",
                text: "100 ml LaVita klar til å drikke",
                asterisk: "1",
            },
            {
                id: "2",
                text: "% per 100 ml klar til å drikke",
                asterisk: "2",
            },
            {
                id: "3",
                text: "2x10 ml LaVita",
                asterisk: "3",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "C-vitamin",
                },
                {
                    id: "1",
                    text: "300 mg",
                },
                {
                    id: "2",
                    text: "375",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "E-vitamin",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "250",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "A-vitamin (som betakaroten)",
                },
                {
                    id: "1",
                    text: "417 µg RE*",
                },
                {
                    id: "2",
                    text: "52",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "B1-vitamin",
                },
                {
                    id: "1",
                    text: "3 mg",
                },
                {
                    id: "2",
                    text: "273",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "B2-vitamin",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Niacin",
                },
                {
                    id: "1",
                    text: "15 mg",
                },
                {
                    id: "2",
                    text: "94",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Pantotensyre",
                },
                {
                    id: "1",
                    text: "8 mg",
                },
                {
                    id: "2",
                    text: "133",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "B6-vitamin",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Folsyre",
                },
                {
                    id: "1",
                    text: "200 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "B12-vitamin",
                },
                {
                    id: "1",
                    text: "5 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Biotin",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "K-vitamin",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "40",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "D-vitamin",
                },
                {
                    id: "1",
                    text: "400 I.E. / 10 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Magnesium",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "8",
                },
                {
                    id: "3",
                    text: "60%",
                },
            ],
            [
                {
                    id: "0",
                    text: "Selen",
                },
                {
                    id: "1",
                    text: "35 µg",
                },
                {
                    id: "2",
                    text: "64",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Kobber",
                },
                {
                    id: "1",
                    text: "0,25 mg",
                },
                {
                    id: "2",
                    text: "25",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Jern",
                },
                {
                    id: "1",
                    text: "4 mg",
                },
                {
                    id: "2",
                    text: "29",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Sink",
                },
                {
                    id: "1",
                    text: "5 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Mangan",
                },
                {
                    id: "1",
                    text: "1 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Krom",
                },
                {
                    id: "1",
                    text: "20 µg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Jod",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "33",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Molybden",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "60",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
        ],
    },
    fr: {
        head: [
            {
                id: "0",
                text: "Micronutrients",
            },
            {
                id: "1",
                text: "100 ml de LaVita prêt a consommer",
                asterisk: "1",
            },
            {
                id: "2",
                text: "% pour 100 ml prêt a consommer",
                asterisk: "2",
            },
            {
                id: "3",
                text: "2x10 ml LaVita",
                asterisk: "3",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "Vitamine C",
                },
                {
                    id: "1",
                    text: "300 mg",
                },
                {
                    id: "2",
                    text: "375",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamine E",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "250",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamine A (bêta-carotène)",
                },
                {
                    id: "1",
                    text: "417 µg RE*",
                },
                {
                    id: "2",
                    text: "52",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Thiamine",
                },
                {
                    id: "1",
                    text: "3 mg",
                },
                {
                    id: "2",
                    text: "273",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Riboflavine",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Niacin",
                },
                {
                    id: "1",
                    text: "15 mg",
                },
                {
                    id: "2",
                    text: "94",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Acide pantothénique",
                },
                {
                    id: "1",
                    text: "8 mg",
                },
                {
                    id: "2",
                    text: "133",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamine B6",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Acide folique",
                },
                {
                    id: "1",
                    text: "200 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamine B12",
                },
                {
                    id: "1",
                    text: "5 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Biotin",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamine K",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "40",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamine D",
                },
                {
                    id: "1",
                    text: "400 U.I. / 10 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Magnésium",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "8",
                },
                {
                    id: "3",
                    text: "60%",
                },
            ],
            [
                {
                    id: "0",
                    text: "Sélénium",
                },
                {
                    id: "1",
                    text: "35 µg",
                },
                {
                    id: "2",
                    text: "64",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Cuivre",
                },
                {
                    id: "1",
                    text: "0,25 mg",
                },
                {
                    id: "2",
                    text: "25",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Fer",
                },
                {
                    id: "1",
                    text: "4 mg",
                },
                {
                    id: "2",
                    text: "29",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Zinc",
                },
                {
                    id: "1",
                    text: "5 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Manganèse",
                },
                {
                    id: "1",
                    text: "1 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Chrome",
                },
                {
                    id: "1",
                    text: "20 µg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Iode",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "33",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Molybdène",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "60",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
        ],
    },
    "fr-CH": {
        head: [
            {
                id: "0",
                text: "Micronutrients",
            },
            {
                id: "1",
                text: "100 ml de LaVita prêt a consommer",
                asterisk: "1",
            },
            {
                id: "2",
                text: "% pour 100 ml prêt a consommer",
                asterisk: "2",
            },
            {
                id: "3",
                text: "2x10 ml LaVita",
                asterisk: "3",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "Vitamine C",
                },
                {
                    id: "1",
                    text: "300 mg",
                },
                {
                    id: "2",
                    text: "375",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamine E",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "250",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamine A (bêta-carotène)",
                },
                {
                    id: "1",
                    text: "417 µg RE*",
                },
                {
                    id: "2",
                    text: "52",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Thiamine",
                },
                {
                    id: "1",
                    text: "3 mg",
                },
                {
                    id: "2",
                    text: "273",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Riboflavine",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Niacin",
                },
                {
                    id: "1",
                    text: "15 mg",
                },
                {
                    id: "2",
                    text: "94",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Acide pantothénique",
                },
                {
                    id: "1",
                    text: "8 mg",
                },
                {
                    id: "2",
                    text: "133",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamine B6",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Acide folique",
                },
                {
                    id: "1",
                    text: "200 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamine B12",
                },
                {
                    id: "1",
                    text: "5 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Biotin",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamine K",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "40",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamine D",
                },
                {
                    id: "1",
                    text: "400 U.I. / 10 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Magnésium",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "8",
                },
                {
                    id: "3",
                    text: "60%",
                },
            ],
            [
                {
                    id: "0",
                    text: "Sélénium",
                },
                {
                    id: "1",
                    text: "35 µg",
                },
                {
                    id: "2",
                    text: "64",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Cuivre",
                },
                {
                    id: "1",
                    text: "0,25 mg",
                },
                {
                    id: "2",
                    text: "25",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Fer",
                },
                {
                    id: "1",
                    text: "4 mg",
                },
                {
                    id: "2",
                    text: "29",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Zinc",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "25",
                },
                {
                    id: "3",
                    text: "50%",
                },
            ],
            [
                {
                    id: "0",
                    text: "Manganèse",
                },
                {
                    id: "1",
                    text: "1 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Chrome",
                },
                {
                    id: "1",
                    text: "20 µg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Iode",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "33",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Molybdène",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "60",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
        ],
    },
    tr: {
        head: [
            {
                id: "0",
                text: "Mikro Besinler",
            },
            {
                id: "1",
                text: "100 ml içime hazır LaVita",
                asterisk: "1",
            },
            {
                id: "2",
                text: "100 ml içime hazır LaVita başına %",
                asterisk: "2",
            },
            {
                id: "3",
                text: "2x10 ml LaVita",
                asterisk: "3",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "Vitamin C",
                },
                {
                    id: "1",
                    text: "300 mg",
                },
                {
                    id: "2",
                    text: "375",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin E",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "250",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin A (beta-karoten olarak)",
                },
                {
                    id: "1",
                    text: "417 µg RE*",
                },
                {
                    id: "2",
                    text: "52",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin B1",
                },
                {
                    id: "1",
                    text: "3 mg",
                },
                {
                    id: "2",
                    text: "273",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin B2",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Niasin",
                },
                {
                    id: "1",
                    text: "15 mg",
                },
                {
                    id: "2",
                    text: "94",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Pantotenik Asit",
                },
                {
                    id: "1",
                    text: "8 mg",
                },
                {
                    id: "2",
                    text: "133",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin B6",
                },
                {
                    id: "1",
                    text: "2,5 mg",
                },
                {
                    id: "2",
                    text: "179",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Folik Asit",
                },
                {
                    id: "1",
                    text: "200 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin B12",
                },
                {
                    id: "1",
                    text: "5 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Biyotin",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "100",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin K",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "40",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vitamin D",
                },
                {
                    id: "1",
                    text: "400 I.E. / 10 µg",
                },
                {
                    id: "2",
                    text: "200",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Magnezyum",
                },
                {
                    id: "1",
                    text: "30 mg",
                },
                {
                    id: "2",
                    text: "8",
                },
                {
                    id: "3",
                    text: "60%",
                },
            ],
            [
                {
                    id: "0",
                    text: "Selenyum",
                },
                {
                    id: "1",
                    text: "35 µg",
                },
                {
                    id: "2",
                    text: "64",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Bakır",
                },
                {
                    id: "1",
                    text: "0,25 mg",
                },
                {
                    id: "2",
                    text: "25",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Demir",
                },
                {
                    id: "1",
                    text: "4 mg",
                },
                {
                    id: "2",
                    text: "29",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Çinko",
                },
                {
                    id: "1",
                    text: "5 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Manganez",
                },
                {
                    id: "1",
                    text: "1 mg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Krom",
                },
                {
                    id: "1",
                    text: "20 µg",
                },
                {
                    id: "2",
                    text: "50",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "İyot",
                },
                {
                    id: "1",
                    text: "50 µg",
                },
                {
                    id: "2",
                    text: "33",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
            [
                {
                    id: "0",
                    text: "Molibden",
                },
                {
                    id: "1",
                    text: "30 µg",
                },
                {
                    id: "2",
                    text: "60",
                },
                {
                    id: "3",
                    text: "check",
                },
            ],
        ],
    },
}

const nutritionalValues = {
    de: {
        head: [
            {
                id: "0",
                text: "Nährwerte",
            },
            {
                id: "1",
                text: "100 ml LaVita-Fertiggetränk",
                asterisk: "1",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "Energie",
                },
                {
                    id: "1",
                    text: "98 kJ / 24 kcal",
                },
            ],
            [
                {
                    id: "0",
                    text: "Fett\n\n- davon ges. Fettsäuren",
                },
                {
                    id: "1",
                    text: "0,32 g\n\n0,06 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Kohlenhydrate\n\n- davon Zucker",
                },
                {
                    id: "1",
                    text: "4,9 g\n\n3,9 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Eiweiß",
                },
                {
                    id: "1",
                    text: "0,26 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Salz",
                },
                {
                    id: "1",
                    text: "< 0,1 g",
                },
            ],
        ],
    },
    en: {
        head: [
            {
                id: "0",
                text: "Nutritional values",
            },
            {
                id: "1",
                text: "100 ml LaVita ready drink",
                asterisk: "1",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "Energy",
                },
                {
                    id: "1",
                    text: "98 kJ / 24 kcal",
                },
            ],
            [
                {
                    id: "0",
                    text: "Fat\n\n- of which saturated fatty acids",
                },
                {
                    id: "1",
                    text: "0,32 g\n\n0,06 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Carbohydrates\n\n- of which sugar",
                },
                {
                    id: "1",
                    text: "4,9 g\n\n3,9 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Protein",
                },
                {
                    id: "1",
                    text: "0,26 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Salt",
                },
                {
                    id: "1",
                    text: "< 0,1 g",
                },
            ],
        ],
    },
    pl: {
        head: [
            {
                id: "0",
                text: "Wartości odżywcze",
            },
            {
                id: "1",
                text: "100 ml gotowego napoju LaVita",
                asterisk: "1",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "Energia",
                },
                {
                    id: "1",
                    text: "98 kJ / 24 kcal",
                },
            ],
            [
                {
                    id: "0",
                    text: "Tłuszcz\n\n- w tym kwasy tłuszczowe nasycone",
                },
                {
                    id: "1",
                    text: "0,32 g\n\n0,06 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Węglowodany\n\n- w tym cukry Białko",
                },
                {
                    id: "1",
                    text: "4,9 g\n\n3,9 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Białko",
                },
                {
                    id: "1",
                    text: "0,26 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Sól",
                },
                {
                    id: "1",
                    text: "< 0,1 g",
                },
            ],
        ],
    },
    da: {
        head: [
            {
                id: "0",
                text: "Ernæringsmæssige værdier",
            },
            {
                id: "1",
                text: "100 ml LaVita ready drink",
                asterisk: "1",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "Energi",
                },
                {
                    id: "1",
                    text: "98 kJ / 24 kcal",
                },
            ],
            [
                {
                    id: "0",
                    text: "Fedt\n\n- heraf mættede fedtsyrer",
                },
                {
                    id: "1",
                    text: "0,32 g\n\n0,06 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Kulhydrat\n\n- heraf sukkerarter",
                },
                {
                    id: "1",
                    text: "4,9 g\n\n3,9 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Protein",
                },
                {
                    id: "1",
                    text: "0,26 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Salt",
                },
                {
                    id: "1",
                    text: "< 0,1 g",
                },
            ],
        ],
    },
    nl: {
        head: [
            {
                id: "0",
                text: "Voedingswaarden",
            },
            {
                id: "1",
                text: "100 ml LaVita drankje bereid",
                asterisk: "1",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "Energie",
                },
                {
                    id: "1",
                    text: "98 kJ / 24 kcal",
                },
            ],
            [
                {
                    id: "0",
                    text: "Vetten\n\n- waarvan verzadigde vetzuren",
                },
                {
                    id: "1",
                    text: "0,32 g\n\n0,06 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Koolhydraten\n\n- waarvan suikers",
                },
                {
                    id: "1",
                    text: "4,9 g\n\n3,9 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Eiwitten",
                },
                {
                    id: "1",
                    text: "0,26 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Zout",
                },
                {
                    id: "1",
                    text: "< 0,1 g",
                },
            ],
        ],
    },
    es: {
        head: [
            {
                id: "0",
                text: "Valores nutricionales",
            },
            {
                id: "1",
                text: "100 ml de bebida preparada LaVita",
                asterisk: "1",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "Valor energético",
                },
                {
                    id: "1",
                    text: "98 kJ / 24 kcal",
                },
            ],
            [
                {
                    id: "0",
                    text: "Grasas\n\n- de las cuales saturadas",
                },
                {
                    id: "1",
                    text: "0,32 g\n\n0,06 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Hidratos de carbono\n\n- de los cuales azúcares",
                },
                {
                    id: "1",
                    text: "4,9 g\n\n3,9 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Proteínas",
                },
                {
                    id: "1",
                    text: "0,26 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Sal",
                },
                {
                    id: "1",
                    text: "< 0,1 g",
                },
            ],
        ],
    },
    cs: {
        head: [
            {
                id: "0",
                text: "nutriční hodnoty",
            },
            {
                id: "1",
                text: "100 ml hotového nápoje LaVita",
                asterisk: "1",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "energie",
                },
                {
                    id: "1",
                    text: "98 kJ / 24 kcal",
                },
            ],
            [
                {
                    id: "0",
                    text: "tuky\n\n- z toho nasycené mastné kyseliny",
                },
                {
                    id: "1",
                    text: "0,32 g\n\n0,06 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "sacharidy\n\n- z toho cukr",
                },
                {
                    id: "1",
                    text: "4,9 g\n\n3,9 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "bílkoviny",
                },
                {
                    id: "1",
                    text: "0,26 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "sůl",
                },
                {
                    id: "1",
                    text: "< 0,1 g",
                },
            ],
        ],
    },
    no: {
        head: [
            {
                id: "0",
                text: "Næringsverdier",
            },
            {
                id: "1",
                text: "100 ml LaVita klar til å drikke",
                asterisk: "1",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "Energi",
                },
                {
                    id: "1",
                    text: "98 kJ / 24 kcal",
                },
            ],
            [
                {
                    id: "0",
                    text: "Fett\n\n- hvorav mettede fettsyrer",
                },
                {
                    id: "1",
                    text: "0,32 g\n\n0,06 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Karbohydrater\n\n- hvorav sukkerarter",
                },
                {
                    id: "1",
                    text: "4,9 g\n\n3,9 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Protein",
                },
                {
                    id: "1",
                    text: "0,26 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Salt",
                },
                {
                    id: "1",
                    text: "< 0,1 g",
                },
            ],
        ],
    },
    it: {
        head: [
            {
                id: "0",
                text: "Valori nutrizionali",
            },
            {
                id: "1",
                text: "100 ml LaVita pronto da bere",
                asterisk: "1",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "Energia",
                },
                {
                    id: "1",
                    text: "98 kJ / 24 kcal",
                },
            ],
            [
                {
                    id: "0",
                    text: "Grassi\n\n- di cui acidi grassi saturi",
                },
                {
                    id: "1",
                    text: "0,32 g\n\n0,06 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Carboidrati\n\n- di cui zuccheri",
                },
                {
                    id: "1",
                    text: "4,9 g\n\n3,9 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Proteine",
                },
                {
                    id: "1",
                    text: "0,26 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Sale",
                },
                {
                    id: "1",
                    text: "< 0,1 g",
                },
            ],
        ],
    },
    sv: {
        head: [
            {
                id: "0",
                text: "Näringsvärden",
            },
            {
                id: "1",
                text: "100 ml LaVita klar til å drikke",
                asterisk: "1",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "Energi",
                },
                {
                    id: "1",
                    text: "98 kJ / 24 kcal",
                },
            ],
            [
                {
                    id: "0",
                    text: "Fett\n\n- hvorav mettede fettsyrer",
                },
                {
                    id: "1",
                    text: "0,32 g\n\n0,06 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Kohlenhydrate\n\n- hvorav sukkerarter",
                },
                {
                    id: "1",
                    text: "4,9 g\n\n3,9 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Protein",
                },
                {
                    id: "1",
                    text: "0,26 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Salt",
                },
                {
                    id: "1",
                    text: "< 0,1 g",
                },
            ],
        ],
    },
    fr: {
        head: [
            {
                id: "0",
                text: "Valeurs nutritionnelles",
            },
            {
                id: "1",
                text: "100 ml de LaVita prêt a consommer",
                asterisk: "1",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "Energie",
                },
                {
                    id: "1",
                    text: "98 kJ / 24 kcal",
                },
            ],
            [
                {
                    id: "0",
                    text: "Matières grasses\n\n- dont acides gras saturés",
                },
                {
                    id: "1",
                    text: "0,32 g\n\n0,06 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Glucides\n\n- dont sucres",
                },
                {
                    id: "1",
                    text: "4,9 g\n\n3,9 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Protéines",
                },
                {
                    id: "1",
                    text: "0,26 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Sel",
                },
                {
                    id: "1",
                    text: "< 0,1 g",
                },
            ],
        ],
    },
    tr: {
        head: [
            {
                id: "0",
                text: "Besin Değerleri",
            },
            {
                id: "1",
                text: "100 ml İçime Hazır İçecek",
                asterisk: "1",
            },
        ],
        body: [
            [
                {
                    id: "0",
                    text: "Enerji",
                },
                {
                    id: "1",
                    text: "98 kJ / 24 kcal",
                },
            ],
            [
                {
                    id: "0",
                    text: "Yağ\n\n- Doymuş Yağ Asitleri",
                },
                {
                    id: "1",
                    text: "0,32 g\n\n0,06 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Karbonhidratlar\n\n- Şeker",
                },
                {
                    id: "1",
                    text: "4,9 g\n\n3,9 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Protein",
                },
                {
                    id: "1",
                    text: "0,26 g",
                },
            ],
            [
                {
                    id: "0",
                    text: "Tuz",
                },
                {
                    id: "1",
                    text: "< 0,1 g",
                },
            ],
        ],
    },
}

const otherSubstances = {
    de: [
        {
            id: "0",
            text: "Weitere Stoffe",
            bold: true,
        },
        {
            id: "1",
            text: "Coenzym Q10: 5 mg",
            bold: false,
        },
        {
            id: "2",
            text: "L-Carnitin: 30 mg",
            bold: false,
        },
    ],
    "de-CH": [
        {
            id: "0",
            text: "Weitere Stoffe",
            bold: true,
        },
        {
            id: "1",
            text: "Coenzym Q10: 5 mg",
            bold: false,
        },
        {
            id: "2",
            text: "L-Carnitin: 30 mg",
            bold: false,
        },
    ],
    en: [
        {
            id: "0",
            text: "Other substances",
            bold: true,
        },
        {
            id: "1",
            text: "Coenzyme Q10: 5 mg",
            bold: false,
        },
        {
            id: "2",
            text: "L-Carnitine: 30 mg",
            bold: false,
        },
    ],
    pl: [
        {
            id: "0",
            text: "Inne substancje",
            bold: true,
        },
        {
            id: "1",
            text: "Coenzym Q10: 5 mg",
            bold: false,
        },
        {
            id: "2",
            text: "L-Karnityna: 30 mg",
            bold: false,
        },
    ],
    da: [
        {
            id: "0",
            text: "Andre stoffer",
            bold: true,
        },
        {
            id: "1",
            text: "Coenzym Q10: 5 mg",
            bold: false,
        },

        {
            id: "2",
            text: "L-Carnitin: 30 mg",
            bold: false,
        },
    ],
    nl: [
        {
            id: "0",
            text: "Andere stoffen",
            bold: true,
        },
        {
            id: "1",
            text: "Co-enzym Q10: 5 mg",
            bold: false,
        },
        {
            id: "2",
            text: "L-Carnitine: 30 mg",
            bold: false,
        },
    ],
    es: [
        {
            id: "0",
            text: "Otras sustancias",
            bold: true,
        },
        {
            id: "1",
            text: "Coenzima Q10: 5 mg",
            bold: false,
        },
        {
            id: "2",
            text: "L-Carnitina: 30 mg",
            bold: false,
        },
    ],
    cs: [
        {
            id: "0",
            text: "Ostatní látky",

            bold: true,
        },

        {
            id: "1",
            text: "Koenzym Q10: 5 mg",
            bold: false,
        },
        {
            id: "2",
            text: "L-Karnitin: 30 mg",

            bold: false,
        },
    ],
    no: [
        {
            id: "0",
            text: "Andre stoffer",
            bold: true,
        },
        {
            id: "1",
            text: "Coenzym Q10: 5 mg",
            bold: false,
        },
        {
            id: "2",
            text: "L-Carnitin: 30 mg",
            bold: false,
        },
    ],
    it: [
        {
            id: "0",
            text: "Altre sostanze",
            bold: true,
        },
        {
            id: "1",
            text: "Coenzima Q10: 5 mg",
            bold: false,
        },
        {
            id: "2",
            text: "L-Carnitina: 30 mg",
            bold: false,
        },
    ],
    sv: [
        {
            id: "0",
            text: "Övriga ämnen",
            bold: true,
        },
        {
            id: "1",
            text: "Koenzym Q10: 5 mg",
            bold: false,
        },
        {
            id: "2",
            text: "L-Karnitin: 30 mg",
            bold: false,
        },
    ],
    fr: [
        {
            id: "0",
            text: "Autres substances",
            bold: true,
        },
        {
            id: "1",
            text: "Coenzyme Q10: 5 mg",
            bold: false,
        },
        {
            id: "2",
            text: "L-Carnitine: 30 mg",
            bold: false,
        },
    ],
    tr: [
        {
            id: "0",
            text: "Diğer Mikro Besinler",
            bold: true,
        },
        {
            id: "1",
            text: "Koenzim Q10: 5 mg",
            bold: false,
        },
        {
            id: "2",
            text: "L-Karnitin: 30 mg",
            bold: false,
        },
    ],
}

const asterisk = {
    de: [
        [
            {
                id: "0",
                text: "1",
            },
            {
                id: "1",
                text: "Unsere Basisempfehlung bestehend aus 10 ml LaVita (ca. 1 Esslöffel) + 90 ml Wasser.",
            },
        ],
        [
            {
                id: "0",
                text: "2",
            },
            {
                id: "1",
                text: "Anteil der Referenzwerte für die tägl. Zufuhr gemäß Europäischer Lebensmittel­informations­verordnung (LMIV)",
            },
        ],
        [
            {
                id: "0",
                text: "3",
            },
            {
                id: "1",
                text: "Unsere Optimalempfehlung: Mit täglich 2x10 ml LaVita (jeweils in Wasser verrührt) werden die Empfehlungen für eine umfassende tägliche Nährstoffversorgung erreicht. Grundlage „Handbuch der Orthomolekularen Medizin“ (Dietl/Ohlenschläger) sowie aktuelle Erkenntnisse der Ernährungswissenschaft",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "1",
                text: "Bitte achten Sie auf eine abwechslungsreiche und ausgewogene Ernährung und auf eine gesunde Lebensweise.",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "1",
                text: "*RE = Retinoläquivalente",
            },
        ],
    ],
    "de-CH": [
        [
            {
                id: "0",
                text: "1",
            },
            {
                id: "1",
                text: "Unsere Basisempfehlung bestehend aus 10 ml LaVita (ca. 1 Esslöffel) + 90 ml Wasser.",
            },
        ],
        [
            {
                id: "0",
                text: "2",
            },
            {
                id: "1",
                text: "Anteil der Referenzwerte für die tägl. Zufuhr gemäß Europäischer Lebensmittel­informations­verordnung (LMIV)",
            },
        ],
        [
            {
                id: "0",
                text: "3",
            },
            {
                id: "1",
                text: "Unsere Optimalempfehlung: Mit täglich 2x10 ml LaVita (jeweils in Wasser verrührt) werden die Empfehlungen für eine umfassende tägliche Nährstoffversorgung erreicht. Grundlage „Handbuch der Orthomolekularen Medizin“ (Dietl/Ohlenschläger) sowie aktuelle Erkenntnisse der Ernährungswissenschaft",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "1",
                text: "Bitte achten Sie auf eine abwechslungsreiche und ausgewogene Ernährung und auf eine gesunde Lebensweise.",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "1",
                text: "*RE = Retinoläquivalente",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "1",
                text: "Nahrungsergänzungsmittel sind kein Ersatz für eine abwechslungsreiche und ausgewogene Ernährung.",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "1",
                text: "Die angegebene empfohlene tägliche Verzehrmenge von 2x10 ml darf nicht überschritten werden. Ausser Reichweite von Kindern aufbewahren. Patientinnen und Patienten, die Antikoagulantien einnehmen, sollten vor der Einnahme von Vitamin K-Präparaten ihre Ärztin oder ihren Arzt konsultieren.",
            },
        ],
    ],
    en: [
        [
            {
                id: "0",
                text: "1",
            },
            {
                id: "1",
                text: "Our basic recommendation is 10 ml LaVita (about 1 tablespoon) + 90 ml water.",
            },
        ],
        [
            {
                id: "0",
                text: "2",
            },
            {
                id: "1",
                text: "Proportion of the reference values for daily intake according to the European Food Information Regulation (FIC Regulation)",
            },
        ],
        [
            {
                id: "0",
                text: "3",
            },
            {
                id: "1",
                text: "Our recommendation for optimal care: 2x10 ml LaVita daily (mixed in water) covers the recommended daily nutrient intake. Based on: 'Handbuch der Orthomolekularen Medizin' (Dietl/Ohlenschlaeger) and the latest findings of nutritional science",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "Please make sure you eat a varied and balanced diet and pursue a healthy lifestyle.",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "*RE = retinol equivalent",
            },
        ],
    ],
    pl: [
        [
            {
                id: "0",
                text: "1",
            },
            {
                id: "1",
                text: "Zalecamy stosowanie 10 ml LaVita (około 1 łyżki stołowej) + 90 ml wody.",
            },
        ],
        [
            {
                id: "0",
                text: "2",
            },
            {
                id: "1",
                text: "Proporcja wartości referencyjnych dla dziennego spożycia zgodnie z europejskim rozporządzeniem w sprawie informacji na temat żywności (Rozporządzenie FIC)",
            },
        ],
        [
            {
                id: "0",
                text: "3",
            },
            {
                id: "1",
                text: "Zalecenie dla optymalnej suplementacji: 2x10 koncentratu LaVita dziennie (zmieszanego z wodą) pokrywa zalecane dzienne spożycie składników odżywczych. Na podstawie: „Handbuch der Orthomolekularen Medizin” (Dietl/Ohlenschlaeger) oraz najnowszych odkryć badań naukowych w dziedzinie żywienia",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "Pamiętaj, aby stosować zróżnicowaną i zbilansowaną dietę oraz prowadzić zdrowy tryb życia.",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "*RE = ekwiwalent retinolu",
            },
        ],
    ],
    da: [
        [
            {
                id: "0",
                text: "1",
            },
            {
                id: "1",
                text: "Vores grundlæggende anbefaling er 10 ml LaVita (ca. 1 spsk.) + 90 ml vand",
            },
        ],
        [
            {
                id: "0",
                text: "2",
            },
            {
                id: "1",
                text: "Andelen af referenceværdierne for dagligt indtag i henhold til EU-forordningen om fødevareinformation (fødevareinformationsforordningen)",
            },
        ],
        [
            {
                id: "0",
                text: "3",
            },
            {
                id: "1",
                text: "Vores anbefaling til optimal brug: 2x10 ml LaVita dagligt (blandet med vand) dækker det anbefalede daglige indtag af næringsstoffer. Baseret på: „Handbuch der Orthomolekularen Medizin“ (Dietl/Ohlenschlaeger) og de nyeste observationer inden for ernæringsvidenskaben",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "Sørg for at spise en sund og varieret kost, og kombiner den med en sund livsstil.",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "*RE = retinolækvivalent",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "**K-vitamin: Patienter, der tager antikoagulantia, bør konsultere deres læge, før de tager K-vitamintilskud.",
            },
        ],
    ],
    nl: [
        [
            {
                id: "0",
                text: "1",
            },
            {
                id: "1",
                text: "Onze basisaanbeveling is 10 ml LaVita (ongeveer 1 eetlepel) + 90 ml water.",
            },
        ],
        [
            {
                id: "0",
                text: "2",
            },
            {
                id: "1",
                text: "Percentage van de dagelijkse referentie-inname volgens de European Food Information Regulation (FIC Regulation)",
            },
        ],
        [
            {
                id: "0",
                text: "3",
            },
            {
                id: "1",
                text: "Onze aanbeveling voor een optimale verzorging: 2x10 ml LaVita dagelijks (vermengd met water) dekt de aanbevolen dagelijkse inname van voedingsstoffen. Op basis van: Handbuch der Orthomolekularen Medizin' (Dietl/Ohlenschlaeger) en de nieuwste inzichten uit de voedingsleer",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "Zorg voor een gevarieerd en evenwichtig dieet en een gezonde levensstijl.",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "*RE = retinolequivalent",
            },
        ],
    ],
    es: [
        [
            {
                id: "0",
                text: "1",
            },
            {
                id: "1",
                text: "Nuestra recomendación básica es 10 ml de la LaVita (aproximadamente 1 cuchara) + 90 ml de agua.",
            },
        ],
        [
            {
                id: "0",
                text: "2",
            },
            {
                id: "1",
                text: "Proporción de valores de referencia para la ingesta diaria según el Reglamento europeo de información alimentaria (Reglamento IAC)",
            },
        ],
        [
            {
                id: "0",
                text: "3",
            },
            {
                id: "1",
                text: "Nuestra recomendación óptima: 2 dosis diarias de 10 ml de LaVita (mezcladas con agua) cubren la ingesta diaria de nutrientes recomendada. Se basa en: Handbuch der Orthomolekularen Medizin (Dietl/Ohlenschlaeger) y los últimos hallazgos en ciencias de la nutrición",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "Procure seguir una dieta variada y equilibrada y llevar un estilo de vida saludable.",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "*RE = equivalente de retinol",
            },
        ],
    ],
    cs: [
        [
            {
                id: "0",
                text: "1",
            },
            {
                id: "1",
                text: "Naše základní doporučení je 10 ml LaVity (přibližně 1 polévková lžíce) + 90 ml vody.",
            },
        ],
        [
            {
                id: "0",
                text: "2",
            },
            {
                id: "1",
                text: "podíl v procentech z denní referenční hodnoty příjmu (RHP) dle nařízení EU č. 1169/2011",
            },
        ],
        [
            {
                id: "0",
                text: "3",
            },
            {
                id: "1",
                text: "Naše doporučení pro optimální zásobení organismu: při 2x10 ml LaVity (vždy rozmíchané do vody a ideálně s rozestuem aspoň čtyř hodin) jsou splněna doporučení pro každodenní komplexní zásobení mikronutrienty. Zdroj: „Handbuch der Orthomolekularen Medizin“ (Dietl/ Ohlenschläger) a podle nejnovějších poznatků nutriční vědy",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "Dbejte prosím na pestrou a vyváženou stravu a zdravý životní styl.",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "*RE = retinol ekvivalent",
            },
        ],
    ],
    no: [
        [
            {
                id: "0",
                text: "1",
            },
            {
                id: "1",
                text: "Vår grunnleggende anbefaling er 10 ml LaVita (ca. 1 ss) + 90 ml vann.",
            },
        ],
        [
            {
                id: "0",
                text: "2",
            },
            {
                id: "1",
                text: "Andel av referanseverdiene for daglig inntak i henhold til European Food Information Regulation (FIC Regulation)",
            },
        ],
        [
            {
                id: "0",
                text: "3",
            },
            {
                id: "1",
                text: "Vår anbefaling for optimal omsorg: 2x10 ml LaVita daglig (blandet i vann) dekker anbefalt daglig næringsinntak. Basert på: 'Handbuch der Orthomolekularen Medizin' (Dietl/Ohlenschlaeger) og de siste funnene innen næringsvitenskap",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "Sørg for at du spiser et variert og balansert kosthold og følger en sunn livsstil.",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "*RE = retinol ekvivalent",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "**K-vitamin: Pasienter som tar blodfortynnende midler, bør rådføre seg med legen sin før de tar vitamin K-tilskudd",
            },
        ],
    ],
    it: [
        [
            {
                id: "0",
                text: "1",
            },
            {
                id: "1",
                text: "Si consiglia di assumere 10 ml di LaVita (circa 1 cucchiaio) + 90 ml di acqua.",
            },
        ],
        [
            {
                id: "0",
                text: "2",
            },
            {
                id: "1",
                text: "Proporzione dei valori di riferimento per l’assunzione quotidiana in base alla European Food Information Regulation (FIC Regulation)",
            },
        ],
        [
            {
                id: "0",
                text: "3",
            },
            {
                id: "1",
                text: "Il nostro suggerimento per una cura ottimale: 2x10 ml di LaVita al giorno (mescolati con acqua) assicurano l’apporto giornaliero di nutrienti consigliato. Basato su: 'Handbuch der Orthomolekularen Medizin' (Dietl/Ohlenschlaeger) e ultime scoperte delle scienze della nutrizione",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "Si raccomanda di seguire una dieta varia ed equilibrata e adottare uno stile di vita sano.",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "*RE = retinolo equivalente",
            },
        ],
    ],
    "it-CH": [
        [
            {
                id: "0",
                text: "1",
            },
            {
                id: "1",
                text: "Si consiglia di assumere 10 ml di LaVita (circa 1 cucchiaio) + 90 ml di acqua.",
            },
        ],
        [
            {
                id: "0",
                text: "2",
            },
            {
                id: "1",
                text: "Proporzione dei valori di riferimento per l’assunzione quotidiana in base alla European Food Information Regulation (FIC Regulation)",
            },
        ],
        [
            {
                id: "0",
                text: "3",
            },
            {
                id: "1",
                text: "Il nostro suggerimento per una cura ottimale: 2x10 ml di LaVita al giorno (mescolati con acqua) assicurano l’apporto giornaliero di nutrienti consigliato. Basato su: 'Handbuch der Orthomolekularen Medizin' (Dietl/Ohlenschlaeger) e ultime scoperte delle scienze della nutrizione",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "Si raccomanda di seguire una dieta varia ed equilibrata e adottare uno stile di vita sano.",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "*RE = retinolo equivalente",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "1",
                text: "Gli integratori alimentari non sostituiscono una dieta varia ed equilibrata.",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "1",
                text: "Non superare la dose giornaliera raccomandata di 2x10 ml. Tenere fuori dalla portata dei bambini. I pazienti che assumono anticoagulanti devono consultare il proprio medico prima di assumere preparati a base di vitamina K.",
            },
        ],
    ],
    sv: [
        [
            {
                id: "0",
                text: "1",
            },
            {
                id: "1",
                text: "Vår grundrekommendation är 10 ml LaVita (ungefär 1 matsked) + 90 ml vatten.",
            },
        ],
        [
            {
                id: "0",
                text: "2",
            },
            {
                id: "1",
                text: "Andel av referensvärdena för dagligt intag i enlighet med European Food Information Regulation (FIC Regulation)",
            },
        ],
        [
            {
                id: "0",
                text: "3",
            },
            {
                id: "1",
                text: "Vår rekommendation för optimal omvårdnad: 2x10 ml LaVita dagligen (blandat i vatten) täcker det dagliga rekommenderade näringsintaget. Baserat på: 'Handbuch der Orthomolekularen Medizin' (Dietl/Ohlenschlaeger) och de senaste fynden inom näringsämnesvetenskapen",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "1",
                text: "Se till att du äter varierad och balanserad kost och följ en hälsosam livsstil.",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "1",
                text: "*RE = retinolekvivalent",
            },
        ],
    ],
    fr: [
        [
            {
                id: "0",
                text: "1",
            },
            {
                id: "1",
                text: "Notre recommandation de base est 10 ml de LaVita (environ 1 cuillère à soupe) + 90 ml d’eau.",
            },
        ],
        [
            {
                id: "0",
                text: "2",
            },
            {
                id: "1",
                text: "Proportion des valeurs de référence pour l’apport journalier selon le règlement concernant l’information des consommateurs sur les denrées alimentaires (règlement INCO)",
            },
        ],
        [
            {
                id: "0",
                text: "3",
            },
            {
                id: "1",
                text: "Notre recommandation pour des soins optimaux : 2 x 10 ml de LaVita par jour (mélangé à de l’eau) couvrent l’apport journalier recommandé en nutriments. Base : Handbuch der Orthomolekularen Medizin (Dietl/Ohlenschlaeger) et les dernières découvertes de la science de la nutrition",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "Veillez à avoir une alimentation variée et équilibrée et à adopter un mode de vie sain.",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "*RE = équivalent rétinol",
            },
        ],
    ],
    "fr-CH": [
        [
            {
                id: "0",
                text: "1",
            },
            {
                id: "1",
                text: "Notre recommandation de base est 10 ml de LaVita (environ 1 cuillère à soupe) + 90 ml d’eau.",
            },
        ],
        [
            {
                id: "0",
                text: "2",
            },
            {
                id: "1",
                text: "Proportion des valeurs de référence pour l’apport journalier selon le règlement concernant l’information des consommateurs sur les denrées alimentaires (règlement INCO)",
            },
        ],
        [
            {
                id: "0",
                text: "3",
            },
            {
                id: "1",
                text: "Notre recommandation pour des soins optimaux : 2 x 10 ml de LaVita par jour (mélangé à de l’eau) couvrent l’apport journalier recommandé en nutriments. Base : Handbuch der Orthomolekularen Medizin (Dietl/Ohlenschlaeger) et les dernières découvertes de la science de la nutrition",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "Veillez à avoir une alimentation variée et équilibrée et à adopter un mode de vie sain.",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "*RE = équivalent rétinol",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "1",
                text: "Les compléments alimentaires ne peuvent pas se substituer à une alimentation variée et équilibrée.",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "1",
                text: "Ne pas dépasser la quantité journalière recommandée de 2x10 ml. Conserver hors de portée des enfants. Les patients qui prennent des anticoagulants devraient consulter leur médecin avant de prendre des préparations de vitamine K.",
            },
        ],
    ],
    tr: [
        [
            {
                id: "0",
                text: "1",
            },
            {
                id: "1",
                text: "Temel tavsiyemiz 10 ml LaVita (yaklaşık 1 yemek kaşığı) + 90 ml (yaklaşık yarım su bardağı) sudan oluşmaktadır.",
            },
        ],
        [
            {
                id: "0",
                text: "2",
            },
            {
                id: "1",
                text: "Avrupa Gıda Bilgilendirme Yönetmeliği’ne (LMIV) göre günlük alım için referans değerlerin payı",
            },
        ],
        [
            {
                id: "0",
                text: "3",
            },
            {
                id: "1",
                text: "Optimal alım için tavsiyemiz: Günlük 2×10 ml LaVita (suda karıştırılmış) ile günlük tavsiye edilen kapsamlı mikro besin öğeleri alınmış olur. Kaynak: „Ortomoleküler Tıp El Kitabı“ (Dietl/Ohlenschläger) Ayrıca beslenme bilimindeki güncel bilgiler tavsiyelerimizi destekler.",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "Lütfen çeşitli ve dengeli beslenmeye, ayrıca sağlıklı bir yaşam tarzına dikkat ediniz.",
            },
        ],
        [
            {
                id: "0",
                text: "",
            },
            {
                id: "0",
                text: "*RE = Retinol Eşdeğeri",
            },
        ],
    ],
}

export { micronutrients, nutritionalValues, asterisk, otherSubstances }
