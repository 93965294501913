import Badge, { BadgeSize } from "./badge"

import React from "react"
import Stars from "./stars"
import { useLocalesState } from "../../../shared/context/locales"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

interface TrustedShopBadgeProps {
    size?: BadgeSize
}

const TrustedShopBadge = ({ size = "md" }: TrustedShopBadgeProps) => {
    const { t } = useTranslation("translations")
    const { language, region } = useLocalesState()

    const { data: trustedShopsData } = useQuery(
        ["getTrustedShops", { id: "chl-a651571a-c49f-4212-8f4a-bfba73bcf931" }],
        () =>
            fetch(
                "https://integrations.etrusted.com/feeds/grades/v1/channels/chl-a651571a-c49f-4212-8f4a-bfba73bcf931/touchpoints/all/feed.json"
            ).then(d => d.json()),
        {
            enabled: language === "de" && region === "DE",
        }
    )

    const rating = trustedShopsData?.grades["365days"].rating
    const count = trustedShopsData?.grades.overall.count

    if (!trustedShopsData) return <Badge size={size} />

    return (
        <div className="flex items-center justify-center">
            <a
                href="https://www.trustedshops.de/bewertung/info_X49E710FC236D0FD6604F8259314C8A7F.html"
                target="_blank"
                rel="noopener noreferrer"
                className="group flex flex-wrap items-center justify-center gap-x-3 gap-y-2 text-base text-dark-grey"
            >
                <div className="flex items-center justify-center gap-x-3">
                    <Stars rating={rating} />
                    <div className="font-bold group-hover:text-blue">{rating.toLocaleString(language)}</div>
                    <div className="group-hover:text-blue">
                        {t(
                            rating < 1.5
                                ? "truststripe_very_poor"
                                : rating < 2.5
                                ? "truststripe_poor"
                                : rating < 3.5
                                ? "truststripe_fair"
                                : rating < 4.5
                                ? "truststripe_good"
                                : "truststripe_excellent"
                        )}
                    </div>
                </div>
                <div className="flex items-center justify-center gap-x-3">
                    <div className="text-dark-grey group-hover:text-blue">
                        {t("truststripe_count", {
                            count: count,
                            countString: count.toLocaleString(language),
                        })}
                    </div>
                    <div className="w-[26px]">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6.481 17.89c0 6.238 5.057 11.294 11.295 11.294 6.237 0 11.294-5.056 11.294-11.294S24.013 6.596 17.776 6.596c-6.238 0-11.295 5.056-11.295 11.294ZM0 17.947C0 8.047 8.025.024 17.924.024c9.9 0 17.924 8.024 17.924 17.923 0 9.9-8.025 17.925-17.924 17.925C8.024 35.872 0 27.847 0 17.947Zm20.192-6.69c.857 0 1.322.644 1.322 1.465 0 2.822-3.322 4.785-5.714 5.499.5-1.964 1.821-6.963 4.392-6.963Zm-3.963 14.784c3.07 0 5.356-2.215 6.999-4.642l-.894-.679c-1.178 1.428-2.784 3.357-4.785 3.357-1.535 0-2.284-1.287-2.284-2.678 0-.679.107-1.357.214-2.035l.498-.125c3.236-.806 8.928-2.223 8.928-6.052 0-2.25-2.499-3-4.356-3-5.535 0-9.462 5.213-9.462 10.533 0 3.285 1.892 5.32 5.142 5.32Z"
                                fill="#000"
                            />
                        </svg>
                    </div>
                </div>
            </a>
        </div>
    )
}

export default TrustedShopBadge
