import React from "react"
import { RenderElementProps } from "slate-react"

const BoxText = ({ children, attributes }: RenderElementProps) => {
    return (
        <p {...attributes} className="text-sm sm:text-base lg:text-md">
            {children}
        </p>
    )
}

export default BoxText
