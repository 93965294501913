import { types, useAdminContext } from "react-bricks/frontend"

import { Container } from "../components/layout"
import React from "react"

interface HTMLProps {
    code: string
}

const HTML: types.Brick<HTMLProps> = ({ code }) => {
    const { isAdmin } = useAdminContext()

    return (
        <Container>
            <div dangerouslySetInnerHTML={{ __html: code }} className={isAdmin ? "my-5" : ""} />
        </Container>
    )
}

HTML.schema = {
    name: "HTML",
    label: "HTML",
    sideEditProps: [
        {
            name: "code",
            label: "Code",
            type: types.SideEditPropType.Textarea,
        },
    ],
}

export default HTML
