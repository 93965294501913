import Typography, { TypographyElement } from "../Typography"

import { Color } from "../components/typography"
import React from "react"
import { types } from "react-bricks/frontend"

interface CheckListProps {
    index?: number
    checkList: TypographyElement[]
}

const CheckList: types.Brick<CheckListProps> = ({ index }) => {
    return (
        <div key={index} className="mt-7 sm:mt-6">
            <Color>
                <Typography propName="checkList" allowList={["centeredCheckList"]} />
            </Color>
        </div>
    )
}

CheckList.schema = {
    name: "CheckList",
    label: "CheckList",
    hideFromAddMenu: true,
    getDefaultProps: () => ({
        checkList: [
            {
                type: "centeredCheckList",
                children: [
                    {
                        type: "CheckList Item",
                        children: [
                            {
                                text: "Lorem ipsum dolor",
                            },
                        ],
                    },
                ],
            },
        ],
    }),
}

export default CheckList
