import React, { useMemo } from "react"

import { Product } from "@lavita-io/international-sdk"
import { formatPrice } from "../../../shared/utils/format"
import { useLocalesState } from "../../../shared/context/locales"
import i18n from "../../../i18n/config"

export interface UpsellingItemPriceAdditionProps {
    product?: Product
}

const getPriceAddition = (product: Product, language: string) => {
    return product.sku === "LV2START" || product.sku === "LVSTARTNA"
        ? i18n.t("upselling_price_addition", {
              basic_price: formatPrice(product.price.amount, product.price.currency, language),
          })
        : undefined
}

const UpsellingItemPriceAddition = ({ product }: UpsellingItemPriceAdditionProps) => {
    const { language } = useLocalesState()

    if (!product) return null

    const priceAddition = useMemo(() => getPriceAddition(product, language), [product, language])
    return <p className="text-right text-sm sm:text-base lg:text-md">{priceAddition}</p>
}

export default UpsellingItemPriceAddition
