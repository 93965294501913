import {
    Background,
    BackgroundColor,
    Container,
    Grid,
    GridColumns,
    Spacer,
    SpacerVariant,
    backgroundGreyScaleColorsOptions,
    gridColumnOneTwoOptions,
} from "../components/layout"
import { ColumnQuoteBackgroundColor, ColumnQuoteProps } from "./ColumnQuote"
import { Repeater, types } from "react-bricks/frontend"

import React from "react"

interface MasterColumnQuoteProps {
    background: { color: BackgroundColor } | BackgroundColor
    columns: GridColumns
    spacerTop: boolean
    spacerBottom: boolean
}

const MasterColumnQuote: types.Brick<MasterColumnQuoteProps> = ({ background, columns, spacerTop, spacerBottom }) => {
    const bgColor = typeof background === "object" ? background.color : background

    return (
        <Background color={bgColor}>
            <Container>
                <Spacer variant={!spacerTop ? SpacerVariant.None : undefined} />
                <Repeater propName="centerText" />
                <Grid columns={columns} as="ul">
                    <Repeater
                        propName="repeater"
                        itemProps={
                            {
                                columns: columns,
                                background:
                                    bgColor === BackgroundColor.White
                                        ? ColumnQuoteBackgroundColor.Grey
                                        : ColumnQuoteBackgroundColor.White,
                            } as ColumnQuoteProps
                        }
                    />
                </Grid>
                <Spacer variant={!spacerBottom ? SpacerVariant.None : undefined} />
            </Container>
        </Background>
    )
}

MasterColumnQuote.schema = {
    name: "MasterColumnQuote",
    label: "Master Column Quote",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "ColumnQuote",
                    label: "Column Quote",
                    min: 1,
                },
            ],
        },
        {
            name: "centerText",
            items: [
                {
                    type: "CenterText",
                    label: "Text",
                    max: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: backgroundGreyScaleColorsOptions,
                display: types.OptionsDisplay.Color,
            },
        },
        {
            name: "columns",
            label: "Columns",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: gridColumnOneTwoOptions,
                display: types.OptionsDisplay.Select,
            },
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): MasterColumnQuoteProps => ({
        background: { color: BackgroundColor.White },
        columns: GridColumns.One,
        spacerTop: true,
        spacerBottom: true,
    }),
}

export default MasterColumnQuote
