import { Align, Color, TypographyAlign } from "../../components/typography"
import ImageEditor, { AspectRatio } from "../../Image"
import { Repeater, types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../../Typography"

import React from "react"
import { TextLinkBrickProps } from "../../text/TextLink"
import { TextLinkSize } from "../../components/text"

export interface ThreeColumnImageTextItemProps {
    text: TypographyElement[]
    index?: number
}

const ThreeColumnImageTextItem: types.Brick<ThreeColumnImageTextItemProps> = ({ index }) => {
    return (
        <li key={index} className="flex flex-col space-y-5 sm:space-y-7">
            <ImageEditor aspectRatio={AspectRatio["5/4"]} maxWidth={717} className="overflow-hidden rounded-base" />
            <Align align={TypographyAlign.Center} className="space-y-3.5 sm:space-y-5">
                <Color>
                    <Typography block="copytext-small" allowList={["h5", "small", "sup", "link", "bold"]} />
                </Color>
                <Repeater propName="repeater" itemProps={{ size: TextLinkSize.Small } as TextLinkBrickProps} />
            </Align>
        </li>
    )
}

ThreeColumnImageTextItem.schema = {
    name: "ThreeColumnImageTextItem",
    label: "3 Column Image Text Item",
    hideFromAddMenu: true,
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "TextLink",
                    label: "Text Link",
                    max: 1,
                },
            ],
        },
    ],
    getDefaultProps: (): ThreeColumnImageTextItemProps => ({
        text: [
            {
                type: "h5",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consetetur",
                        bold: true,
                    },
                ],
            },
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
                    },
                ],
            },
        ],
    }),
}

export default ThreeColumnImageTextItem
