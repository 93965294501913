import React from "react"
import { types } from "react-bricks/frontend"
import ImageEditor, { AspectRatio } from "../../Image"
import Typography, { TypographyElement } from "../../Typography"

interface ThreeColumnImageListItemProps {
    index?: number
    text: TypographyElement[]
}

const ThreeColumnImageListItem: types.Brick<ThreeColumnImageListItemProps> = ({ index }) => {
    return (
        <li key={index} className="flex items-start gap-5 lg:gap-7">
            <ImageEditor
                aspectRatio={AspectRatio["1/1"]}
                maxWidth={150}
                className="max-h-20 max-w-20 rounded-base md:max-h-25 md:max-w-25 lg:max-h-[130px] lg:max-w-[130px] xl:max-h-[150px] xl:max-w-[150px]"
            />
            <div className="grid min-h-20 items-center md:min-h-25 lg:min-h-[130px] xl:min-h-[150px]">
                <Typography block="copytext-large" allowList={["bold"]} />
            </div>
        </li>
    )
}

ThreeColumnImageListItem.schema = {
    name: "ThreeColumnImageListItem",
    label: "3 Column Image List Item",
    hideFromAddMenu: true,
    getDefaultProps: (): ThreeColumnImageListItemProps => ({
        text: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr.",
                        bold: true,
                    },
                ],
            },
        ],
    }),
}

export default ThreeColumnImageListItem
