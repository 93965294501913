import React, { useCallback, useId, useState } from "react"
import Typography, { TypographyElement } from "../Typography"

import { BackgroundColor } from "../components/layout"
import Divider from "../decorative/Divider"
import { Icon } from "../components/icon"
import { Icons } from "../../types"
import classNames from "classnames"
import { types } from "react-bricks/frontend"
import { useMediaQuery } from "usehooks-ts"

interface FAQProps {
    textVisible: TypographyElement[]
    textHidden: TypographyElement[]
    index?: number
    bgColor?: BackgroundColor
    setIsOpen: (index?: number | ((isOpen: number) => number | undefined)) => void
    isOpen: number
    id: string
}

const FAQ: types.Brick<FAQProps> = ({ index, setIsOpen, isOpen, id }) => {
    const [isOpenMobile, setIsOpenMobile] = useState(index === isOpen)

    const isMobile = useMediaQuery("(max-width: 767px")

    const open = useCallback(
        (index?: number) => (isMobile ? isOpenMobile : isOpen === index),
        [isMobile, isOpen, isOpenMobile]
    )

    const triggerId = useId()
    const contentId = useId()

    return (
        <li key={index}>
            <button
                type="button"
                onClick={() => {
                    setIsOpen((isOpen: number) => (index === isOpen ? undefined : index))
                    setIsOpenMobile(isOpenMobile => !isOpenMobile)
                }}
                className="flex w-full justify-between text-left"
                id={triggerId}
                aria-expanded={open(index)}
                aria-controls={contentId}
            >
                <Typography propName="textVisible" allowList={["bold"]} />
                <Icon
                    icon={Icons.arrow_down}
                    className={classNames("transition duration-300 ease-in-out", { "rotate-180": open(index) })}
                />
            </button>
            <div
                className={classNames(
                    "grid transition-grid-template-rows duration-300 ease-out",
                    open(index) ? "grid-rows-1fr" : "grid-rows-0fr"
                )}
                id={contentId}
                aria-labelledby={triggerId}
                aria-hidden={!open(index)}
            >
                <div className="overflow-hidden">
                    <div className="mt-5 sm:mt-7.5 xl:pr-12">
                        <Typography
                            allowList={["bold", "sup", "link", "small"]}
                            propName="textHidden"
                            hidden={!open(index)}
                        />
                    </div>
                </div>
            </div>
            <div className="mb-7 mt-5 sm:my-10">
                <Divider spacerTop={false} spacerBottom={false} />
            </div>
        </li>
    )
}

FAQ.schema = {
    name: "FAQ",
    label: "FAQ",
    hideFromAddMenu: true,
    getDefaultProps: (): Omit<FAQProps, "setIsOpen" | "isOpen" | "id"> => ({
        textVisible: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet.",
                        bold: true,
                    },
                ],
            },
        ],
        textHidden: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
                    },
                ],
            },
        ],
    }),
}

export default FAQ
