import React, { ReactNode } from "react"

import classNames from "classnames"
import { options } from "../../../types/options"
import { twMerge } from "tailwind-merge"

export enum GridColumns {
    "Four" = "4",
    "Three" = "3",
    "Two" = "2",
    "One" = "1",
}

export enum GridGap {
    "Base" = "base",
    "Large" = "lg",
}

export interface GridProps {
    children: ReactNode
    columns?: GridColumns
    gapY?: GridGap
    gapX?: GridGap
    allwaysColumns?: boolean
    as?: "ul" | "div"
}

const Grid = ({
    children,
    columns = GridColumns.Three,
    gapY = GridGap.Base,
    gapX = GridGap.Base,
    allwaysColumns,
    as = "div",
}: GridProps) => {
    return React.createElement(
        as,
        {
            className: twMerge(
                classNames("grid grid-cols-1 gap-x-5 gap-y-5 sm:grid-cols-2 lg:gap-x-6 lg:gap-y-6", {
                    "gap-y-7 sm:gap-y-10 lg:gap-y-16 xl:gap-y-24": gapY === GridGap.Large,
                    "gap-x-5 lg:gap-x-10": gapX === GridGap.Large,
                    "sm:grid-cols-2": columns === GridColumns.Two || columns == GridColumns.Three,
                    "md:grid-cols-3": columns === GridColumns.Three,
                    "sm:grid-cols-1": columns === GridColumns.One,
                    "grid-cols-3 sm:grid-cols-3": allwaysColumns && columns === GridColumns.Three,
                    "grid-cols-2": allwaysColumns && columns === GridColumns.Two,
                    "grid-cols-1 gap-x-5 gap-y-7 sm:grid-cols-4 sm:gap-x-3.5 lg:gap-5": columns === GridColumns.Four,
                    "grid-cols-2 gap-x-2.5 gap-y-7 sm:grid-cols-4 sm:gap-x-3.5 md:grid-cols-4 lg:gap-5":
                        allwaysColumns && columns === GridColumns.Four,
                })
            ),
        },
        children
    )
}

export const gridColumnOptions = options<typeof GridColumns>(GridColumns)
export const gridColumnOneTwoOptions = gridColumnOptions.filter(option => option.value !== GridColumns.Three)
export const gridColumnTwoThreeOptions = gridColumnOptions.filter(option => option.value !== GridColumns.One)

export const gridGapOptions = options<typeof GridGap>(GridGap)

export default Grid
