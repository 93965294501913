import {
    Align,
    Color,
    MaxWidth,
    TypographyAlign,
    TypographyColor,
    TypographyMaxWidth,
    alignOptions,
} from "../components/typography"
import { Background, BackgroundColor, Container, Spacer, SpacerVariant, backgroundOptions } from "../components/layout"
import { Repeater, types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../Typography"

import { ConditionalWrapper } from "../components/util"
import React from "react"
import { TextLinkBrickProps } from "./TextLink"
import { TextLinkColor } from "../components/text"
import classNames from "classnames"

interface BasicTextProps {
    background: { color: BackgroundColor } | BackgroundColor
    align: TypographyAlign
    text: TypographyElement[]
    spacerTop: boolean
    spacerBottom: boolean
    id?: string
    box?: boolean
    boxOutline?: boolean
}

const BasicText: types.Brick<BasicTextProps> = ({
    background,
    align,
    spacerTop,
    spacerBottom,
    id,
    box,
    boxOutline,
}) => {
    const bgColor = typeof background === "object" ? background.color : background

    return (
        <Background color={bgColor}>
            <Spacer variant={!spacerTop ? SpacerVariant.None : undefined} />
            <Container>
                <ConditionalWrapper
                    condition={!!(box || boxOutline)}
                    wrapper={children => (
                        <div
                            className={classNames("rounded-base px-5 py-7 sm:p-10 lg:py-16", {
                                "bg-white": box && bgColor !== BackgroundColor.White,
                                "bg-background-tide": box && bgColor === BackgroundColor.White,
                                border: boxOutline,
                                "border-border-tide": boxOutline && bgColor === BackgroundColor.White,
                                "border-dark-grey": boxOutline && bgColor === BackgroundColor.Grey,
                                "border-white":
                                    boxOutline &&
                                    (bgColor === BackgroundColor.Green || bgColor === BackgroundColor.DarkBlumine),
                            })}
                        >
                            {children}
                        </div>
                    )}
                >
                    <Color
                        color={
                            box ? TypographyColor.Darkgrey : bgColor === BackgroundColor.Green || bgColor === BackgroundColor.DarkBlumine
                                ? TypographyColor.White
                                : TypographyColor.Darkgrey
                        }
                    >
                        <Align align={align}>
                            <MaxWidth lgMaxWidth={TypographyMaxWidth["70%"]} className="space-y-7 md:space-y-10">
                                <Typography
                                    id={id}
                                    allowList={[
                                        "h1",
                                        "h2",
                                        "h3",
                                        "h4",
                                        "ul",
                                        "small",
                                        "sup",
                                        "link",
                                        "bold",
                                        "italic",
                                        "quote",
                                        "checkList",
                                    ]}
                                />
                                <Repeater
                                    propName="repeater"
                                    itemProps={
                                        {
                                            color:
                                                bgColor === BackgroundColor.Green ||
                                                bgColor === BackgroundColor.DarkBlumine
                                                    ? TextLinkColor.White
                                                    : TextLinkColor.Blue,
                                        } as TextLinkBrickProps
                                    }
                                    renderWrapper={children => (
                                        <div className="flex flex-col space-y-5 sm:space-y-7">{children}</div>
                                    )}
                                />
                            </MaxWidth>
                        </Align>
                    </Color>
                </ConditionalWrapper>
            </Container>
            <Spacer variant={!spacerBottom ? SpacerVariant.None : undefined} />
        </Background>
    )
}

BasicText.schema = {
    name: "BasicText",
    label: "Basic Text",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "Button",
                    label: "Button",
                    max: 1,
                },
                {
                    type: "TextLink",
                    label: "Text Link",
                    max: 2,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "id",
            label: "ID",
            type: types.SideEditPropType.Text,
        },
        {
            name: "align",
            label: "Align",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: alignOptions,
                display: types.OptionsDisplay.Select,
            },
        },
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: backgroundOptions,
                display: types.OptionsDisplay.Color,
            },
        },
        {
            name: "box",
            label: "Box",
            type: types.SideEditPropType.Boolean,
        },
        {
            name: "boxOutline",
            label: "Box-Outline",
            type: types.SideEditPropType.Boolean,
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): BasicTextProps => ({
        align: TypographyAlign.Left,
        background: { color: BackgroundColor.White },
        text: [
            {
                type: "h1",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet",
                    },
                ],
            },
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc placerat sagittis faucibus.",
                    },
                ],
            },
        ],
        spacerTop: true,
        spacerBottom: true,
        box: false,
        boxOutline: false,
    }),
}

export default BasicText
