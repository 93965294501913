import {
    Big,
    BoxText,
    CenteredUl,
    CheckList,
    CopytextCard,
    CopytextExtraLarge,
    CopytextExtraSmall,
    CopytextImageTextSlider,
    CopytextLarge,
    CopytextSmall,
    CopytextTeaser,
    H1,
    H2,
    H3,
    H4,
    H5,
    Italic,
    Li,
    Quote,
    QuoteText,
    QuoteTextImage,
    QuoteTextImageBottom,
    QuoteTextSmall,
    SEOH1,
    Small,
    SmallCheckList,
    SmallUl,
    Strong,
    SuperSized,
    Ul,
} from "./components/typography"
import { BigIcon, CheckListIcon, QuoteIcon, SeoIcon, SmallIcon, SupIcon, SuperSizedIcon } from "./components/svg"
import { Link, RichTextExt, blockPluginConstructor, markPluginConstructor, plugins, types } from "react-bricks/frontend"
import { RenderElementProps, RenderLeafProps } from "slate-react"

import CopytextImageTextColumn from "./components/typography/copytext-image-text-column"
import React from "react"

export type TypographyBlock =
    | "box-text"
    | "copytext-large"
    | "copytext-extra-large"
    | "copytext-small"
    | "copytext-card"
    | "copytext-extra-small"
    | "copytext-teaser"
    | "quote-text"
    | "quote-text-image"
    | "quote-text-image-bottom"
    | "quote-text-small"
    | "image-text-slider"
    | "image-text-column"
export type TypographyAllowItem =
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "ul"
    | "link"
    | "bold"
    | "sup"
    | "superSized"
    | "small"
    | "smallCheckList"
    | "italic"
    | "checkList"
    | "centeredCheckList"
    | "big"
    | "quote"
    | "seo-h1"

export interface TypographyProps {
    block?: TypographyBlock
    allowList?: TypographyAllowItem[]
    propName?: string
    id?: string
    hidden?: boolean
}

type TypographyText = {
    text?: string
    bold?: true
    sup?: true
    italic?: true
    big?: true
    superSized?: true
    type?: "li" | "CheckList Item"
    children?: TypographyText[]
}

export type TypographyElement = {
    type: "paragraph" | TypographyAllowItem
    children: TypographyText[]
}

const Typography = ({
    block = "copytext-large",
    allowList = [
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "ul",
        "link",
        "bold",
        "sup",
        "superSized",
        "small",
        "smallCheckList",
        "italic",
        "checkList",
        "centeredCheckList",
        "big",
        "quote",
        "seo-h1",
    ],
    propName = "text",
    id,
    hidden = false,
}: TypographyProps) => {
    const blocks = {
        "box-text": BoxText,
        "copytext-large": CopytextLarge,
        "copytext-extra-large": CopytextExtraLarge,
        "copytext-small": CopytextSmall,
        "copytext-card": CopytextCard,
        "copytext-extra-small": CopytextExtraSmall,
        "copytext-teaser": CopytextTeaser,
        "quote-text": QuoteText,
        "quote-text-image": QuoteTextImage,
        "quote-text-small": QuoteTextSmall,
        "image-text-slider": CopytextImageTextSlider,
        "quote-text-image-bottom": QuoteTextImageBottom,
        "image-text-column": CopytextImageTextColumn,
    }

    const { heading1, heading2, heading3, heading4, heading5, unorderedList, link, bold, italic } = plugins

    const sup = markPluginConstructor({
        name: "sup",
        label: "Superscript",
        render: ({ children, attributes }: RenderLeafProps) => <sup {...attributes}>{children}</sup>,
        icon: SupIcon,
    })

    const small = blockPluginConstructor({
        name: "small",
        label: "Small",
        render: Small,
        icon: SmallIcon,
    })

    const seoHeading = blockPluginConstructor({
        name: "seo-h1",
        label: "SEO Heading",
        render: SEOH1,
        icon: SeoIcon,
    })

    const centeredCheckList = blockPluginConstructor({
        name: "centeredCheckList",
        label: "CheckList",
        itemName: "CheckList Item",
        render: CenteredUl,
        renderItem: CheckList,
        icon: CheckListIcon,
    })

    const checkList = blockPluginConstructor({
        name: "checkList",
        label: "CheckList",
        itemName: "CheckList Item",
        render: Ul,
        renderItem: CheckList,
        icon: CheckListIcon,
    })

    const big = markPluginConstructor({
        name: "big",
        label: "Big",
        render: Big,
        icon: BigIcon,
    })

    const quote = markPluginConstructor({
        name: "quote",
        label: "Quote",
        render: Quote,
        icon: QuoteIcon,
    })

    const smallCheckList = blockPluginConstructor({
        name: "smallCheckList",
        label: "SmallCheckList",
        itemName: "CheckList Item",
        render: SmallUl,
        renderItem: SmallCheckList,
        icon: CheckListIcon,
    })

    const superSized = markPluginConstructor({
        name: "superSized",
        label: "Super Sized",
        render: SuperSized,
        icon: SuperSizedIcon,
    })

    const allowedPlugins: types.RichTextPlugin[] = [
        {
            ...heading1,
            renderElement: H1,
        },
        {
            ...heading2,
            renderElement: H2,
        },
        {
            ...heading3,
            renderElement: H3,
        },
        {
            ...heading4,
            renderElement: H4,
        },
        {
            ...heading5,
            renderElement: H5,
        },
        {
            ...unorderedList,
            renderElement: Ul,
            renderItemElement: Li,
        },
        {
            ...link,
            renderElement: ({ children, attributes, element }: RenderElementProps) => (
                <Link
                    {...attributes}
                    href={element.data?.url || element.url}
                    target={element.data?.isTargetBlank ? "_blank" : undefined}
                    rel={element.data?.isTargetBlank ? "noopener" : undefined}
                    className="link [&:not(:has(sup))]:underline hover:[&_sup]:underline"
                    tabIndex={hidden ? -1 : undefined}
                >
                    {children}
                </Link>
            ),
        },
        {
            ...bold,
            renderLeaf: Strong,
        },
        {
            ...italic,
            renderLeaf: Italic,
        },
        sup,
        seoHeading,
        small,
        smallCheckList,
        centeredCheckList,
        checkList,
        big,
        quote,
        superSized,
    ].filter(p => allowList.includes(p.name as TypographyAllowItem))

    return (
        <div id={id} className="relative">
            <RichTextExt propName={propName} placeholder="" renderBlock={blocks[block]} plugins={allowedPlugins} />
        </div>
    )
}

export default Typography
