import {
    Align,
    Color,
    MaxWidth,
    TypographyAlign,
    TypographyColor,
    TypographyMaxWidth,
    alignOptions,
    maxWidthOptions,
} from "../components/typography"
import ImageEditor, { AspectRatio } from "../Image"
import React, { ReactNode, RefObject, useEffect, useRef } from "react"
import { Repeater, types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../Typography"

import Badge from "../components/trust/badge"
import { ButtonBrickProps } from "../button/Button"
import { ButtonStyle } from "../components/button"
import { CloudflarePlayer } from "../components/video"
import { Container } from "../components/layout"
import { StreamPlayerApi } from "@cloudflare/stream-react"
import TrustedShopBadge from "../components/trust/trusted-shop-badge"
import classNames from "classnames"
import { options } from "../../types"
import { useIntersectionObserver } from "usehooks-ts"
import { useLocalesState } from "../../shared/context/locales"

export enum HeroIntroStyle {
    Positive = "positive",
    Negative = "negative",
}

interface HeroIntroProps {
    video: boolean
    style: HeroIntroStyle
    align: TypographyAlign
    maxWidth: TypographyMaxWidth
    text: TypographyElement[]
    headline: TypographyElement[]
    cloudflareVideoId?: string
    cloudflareVideoIdMobile?: string
    isDecorativeVideo?: boolean
}

interface PlayerProps {
    video: boolean
    id?: string
    isIntersecting: boolean
    image: ReactNode
    wrapper: (children: JSX.Element) => JSX.Element
    wrapperRef: RefObject<HTMLDivElement>
    className?: string
    isDecorativeVideo?: boolean
}

const Player = ({ video, id, isIntersecting, image, wrapper, wrapperRef, className }: PlayerProps) => {
    const player = useRef<StreamPlayerApi>()

    const wrapperIntersectionObserver = useIntersectionObserver(wrapperRef, {
        rootMargin: "256px 256px 256px 256px",
    })

    useEffect(() => {
        if (!wrapperIntersectionObserver || !player.current) return

        if (!wrapperIntersectionObserver.isIntersecting) {
            player.current.pause()
        } else {
            try {
                player.current.play()
            } catch (e) {
                player.current.muted = true
                player.current.play()
            }
        }
    }, [wrapperIntersectionObserver?.isIntersecting])

    return wrapper(
        <>
            {image}
            {video && isIntersecting && <CloudflarePlayer id={id} autoplay streamRef={player} />}
        </>
    )
}

const HeroIntro: types.Brick<HeroIntroProps> = ({
    style,
    video,
    cloudflareVideoId,
    cloudflareVideoIdMobile,
    align,
    maxWidth,
    isDecorativeVideo,
}) => {
    const { language, region } = useLocalesState()

    const mobileVideoRef = useRef<HTMLIFrameElement>(null)
    const videoRef = useRef<HTMLIFrameElement>(null)

    const mobileVideoEntry = useIntersectionObserver(mobileVideoRef, {
        rootMargin: "1256px 256px 1256px 256px",
        freezeOnceVisible: true,
    })
    const videoEntry = useIntersectionObserver(videoRef, {
        rootMargin: "1256px 256px 1256px 256px",
        freezeOnceVisible: true,
    })

    return (
        <div>
            <div className="relative">
                <div className="relative" aria-hidden={isDecorativeVideo}>
                    <Player
                        id={cloudflareVideoIdMobile}
                        isIntersecting={!!mobileVideoEntry?.isIntersecting}
                        image={
                            <ImageEditor
                                aspectRatio={AspectRatio["3/2"]}
                                maxWidth={768}
                                className="w-full"
                                propName="image_mobile"
                            />
                        }
                        wrapper={children => (
                            <div className="aspect-h-2 aspect-w-3 w-full sm:hidden" ref={mobileVideoRef}>
                                {children}
                            </div>
                        )}
                        wrapperRef={mobileVideoRef}
                        video={video}
                    />
                    <Player
                        id={cloudflareVideoId}
                        isIntersecting={!!videoEntry?.isIntersecting}
                        image={
                            <ImageEditor
                                aspectRatio={AspectRatio["16/9"]}
                                maxWidth={1920}
                                className="w-full"
                                propName="image"
                            />
                        }
                        wrapper={children => (
                            <div className="hidden sm:aspect-h-9 sm:aspect-w-16 sm:block" ref={videoRef}>
                                {children}
                            </div>
                        )}
                        wrapperRef={videoRef}
                        video={video}
                    />
                </div>
                <div className="absolute top-1/2 w-full -translate-y-1/2 justify-center">
                    <Container>
                        <Align align={align}>
                            <MaxWidth
                                smMaxWidth={
                                    maxWidth === TypographyMaxWidth.Half
                                        ? TypographyMaxWidth["70%"]
                                        : maxWidth || TypographyMaxWidth["70%"]
                                }
                                mdMaxWidth={maxWidth || TypographyMaxWidth.Half}
                                lgMaxWidth={maxWidth || TypographyMaxWidth.Half}
                            >
                                <Color
                                    color={
                                        style === HeroIntroStyle.Positive ? TypographyColor.Blue : TypographyColor.White
                                    }
                                >
                                    <div className="hidden w-full sm:mb-5 sm:block lg:mb-7">
                                        <Typography
                                            allowList={["h1", "h3", "sup", "link", "seo-h1"]}
                                            propName="headline"
                                        />
                                    </div>
                                </Color>
                            </MaxWidth>
                            <div className="hidden sm:block">
                                <Repeater
                                    propName="repeater"
                                    renderWrapper={children => (
                                        <div
                                            className={classNames("mt-7 flex space-x-2 md:mt-10 md:space-x-2.5", {
                                                "justify-center": align === TypographyAlign.Center,
                                            })}
                                        >
                                            {children}
                                        </div>
                                    )}
                                />
                            </div>
                        </Align>
                    </Container>
                </div>
            </div>
            <div className="border-b border-border-tide pt-7 sm:border-none sm:bg-background-tide sm:py-10 md:py-16 lg:py-24">
                <Container>
                    <div className="grid-cols-1 grid-rows-1 sm:grid">
                        <div className="-z-1 col-span-full row-span-full hidden w-full shrink-0 self-end opacity-0 sm:mr-5 sm:block sm:w-[308px] md:mr-6 md:w-[416px] lg:mr-24 lg:w-[530px] xl:mr-40">
                            <ImageEditor
                                aspectRatio={AspectRatio["4/5"]}
                                maxWidth={717}
                                propName="image_bottom"
                                className="w-full"
                            />
                        </div>
                        <div className="relative col-span-full row-span-full mt-auto items-center justify-between sm:flex sm:rounded-base sm:bg-white md:h-[480px] lg:h-[600px]">
                            <div className="box-content sm:max-w-[344px] sm:py-10 sm:pl-10 md:max-w-[426px] md:pl-16 lg:max-w-[510px] lg:pl-24 xl:pl-40">
                                <Align align={TypographyAlign["Left + mobile center"]}>
                                    <Color>
                                        <div className="mb-5 sm:hidden lg:mb-7">
                                            <Typography
                                                allowList={["h1", "h3", "sup", "link", "seo-h1"]}
                                                propName="headline"
                                            />
                                        </div>
                                        <Typography
                                            allowList={["ul", "small", "sup", "link", "bold", "checkList"]}
                                            block="copytext-extra-large"
                                            propName="text"
                                        />
                                    </Color>
                                </Align>
                                <Repeater
                                    propName="repeater"
                                    renderWrapper={children => (
                                        <div className="mt-5 flex space-x-2 sm:hidden">{children}</div>
                                    )}
                                    itemProps={{ style: ButtonStyle.Primary } as ButtonBrickProps}
                                />
                                <div className="flex justify-center sm:justify-start">
                                    <div className="mt-5 sm:mt-7 sm:rounded-base sm:p-5 sm:shadow-base md:mt-10">
                                        {language === "de" && region === "DE" ? (
                                            <TrustedShopBadge size="sm" />
                                        ) : (
                                            <Badge size="sm" />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="bottom-0 right-0 mt-7 w-full shrink-0 sm:absolute sm:mr-5 sm:mt-0 sm:w-[308px] md:mr-6 md:w-[416px] lg:mr-24 lg:w-[530px] xl:mr-40">
                                <ImageEditor
                                    aspectRatio={AspectRatio["4/5"]}
                                    maxWidth={717}
                                    propName="image_bottom"
                                    className="w-full"
                                />
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export const styleOptions = options<typeof HeroIntroStyle>(HeroIntroStyle)

HeroIntro.schema = {
    name: "HeroIntro",
    label: "Hero Intro",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "Button",
                    label: "Button",
                    max: 1,
                },
                {
                    type: "ImageButton",
                    label: "ImageButton",
                    max: 2,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "align",
            label: "Align",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: alignOptions,
                display: types.OptionsDisplay.Select,
            },
        },
        {
            name: "maxWidth",
            label: "Max Width",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: maxWidthOptions,
                display: types.OptionsDisplay.Select,
            },
        },
        {
            name: "style",
            label: "Style",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: styleOptions,
                display: types.OptionsDisplay.Select,
            },
        },
        {
            name: "video",
            label: "Video",
            type: types.SideEditPropType.Boolean,
        },
        {
            name: "cloudflareVideoId",
            label: "Cloudflare Video Id",
            type: types.SideEditPropType.Text,
            show: props => props.video,
        },
        {
            name: "cloudflareVideoIdMobile",
            label: "Cloudflare Video Id Mobile",
            type: types.SideEditPropType.Text,
            show: props => props.video,
        },
    ],
    getDefaultProps: (): HeroIntroProps => ({
        style: HeroIntroStyle.Positive,
        align: TypographyAlign.Left,
        maxWidth: TypographyMaxWidth.Half,
        video: false,
        headline: [
            {
                type: "h3",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet",
                    },
                ],
            },
        ],
        text: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc placerat sagittis faucibus.",
                    },
                ],
            },
        ],
    }),
}

export default HeroIntro
