import React, { ReactNode, useRef } from "react"

import Container from "../layout/container"
import SliderNavigation from "./navigation"
import classNames from "classnames"
import { useSlider } from "../../../hooks/use-slider"
import { useWindowSize } from "../../../hooks/use-window-size-admin"

interface GridSliderProps {
    children: (index: number) => ReactNode
    length: number
}

const GridSlider = ({ children, length }: GridSliderProps) => {
    const sliderRef = useRef<null | HTMLDivElement>(null)

    const { handleDragStart, handleDragMove, handleDragEnd, index, lastIndex, transition, goTo } = useSlider(
        length,
        sliderRef,
        0
    )

    const { width } = useWindowSize({ initializeWithValue: false })

    return (
        <div className="overflow-hidden" ref={sliderRef}>
            <Container>
                <ul
                    className={classNames("grid gap-x-2.5 sm:gap-x-3.5 md:gap-x-5 lg:gap-x-6", {
                        "transition-transform duration-[250ms] ease-in-out": transition,
                        "cursor-grab active:cursor-grabbing": length > 1,
                    })}
                    style={
                        {
                            width:
                                // @ts-ignore
                                width < 768
                                    ? `calc(100% + 80% * ${length - 1} + var(--gap) * ${length - 1})`
                                    : `calc(((100% - 2 * var(--gap)) / 3) * ${length} + var(--gap) * ${length - 1})`,
                            transform:
                                // @ts-ignore
                                width < 768
                                    ? `translateX(calc(${-1 * index} * (((100% + var(--gap)) / (${
                                          length * 4 + 1
                                      })) * 4)))`
                                    : `translateX(calc(${-1 * index} * (100% + var(--gap)) / ${length}))`,
                            gridTemplateColumns:
                                // @ts-ignore
                                width < 768 ? `repeat(${length * 4 + 1}, 1fr)` : `repeat(${length}, 1fr)`,
                            // @ts-ignore
                            "--gap": `${width < 768 ? 0.625 : width < 1024 ? 0.875 : width < 1366 ? 1.25 : 1.5}rem`,
                        } as React.CSSProperties
                    }
                    onTouchStart={handleDragStart}
                    onMouseDown={handleDragStart}
                    onTouchMove={handleDragMove}
                    onMouseMove={handleDragMove}
                    onTouchEnd={handleDragEnd}
                    onMouseUp={handleDragEnd}
                    onMouseLeave={handleDragEnd}
                    role="none"
                >
                    {children(index)}
                </ul>
                <SliderNavigation length={length} lastIndex={lastIndex} goTo={goTo} />
            </Container>
        </div>
    )
}

export default GridSlider
