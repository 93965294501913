import React from "react"
import { RenderElementProps } from "slate-react"

const CenteredUl = ({ children, attributes }: RenderElementProps) => {
    return (
        <ul {...attributes} className="mb-4 list-none text-base last:mb-0 sm:mb-5 sm:text-md sm:flex justify-center gap-7">
            {children}
        </ul>
    )
}

export default CenteredUl
