import { useEffect, useState } from "react"

const useCCMLoaded = () => {
    const [ccmLoaded, setCCMLoaded] = useState(false)

    useEffect(() => {
        const handleCCM = () => {
            setCCMLoaded(!!window.CCM)
        }

        handleCCM()
        window.addEventListener("ccm19WidgetLoaded", handleCCM)
        return () => {
            window.removeEventListener("ccm19WidgetLoaded", handleCCM)
        }
    }, [])

    return ccmLoaded
}

export { useCCMLoaded }
